import React from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'

import KiwiChat from './KiwiChat'
import Auth from './Auth'


class KiwiHome extends React.Component {

  urlBotKey = (props=this.props) => props.match.params.botKey

  componentDidMount() {
    //init chat from url hash
    // var urlHash = this.props.location.hash
    // if(urlHash) {
    //   var hashBotKey = urlHash.replace(/#/g, '')
    //   this.props.actions.startKiwiChat(hashBotKey)
    // }
    this.init()
  }

  componentWillReceiveProps(nextProps) {
    if(this.urlBotKey() != this.urlBotKey(nextProps))
      this.init(nextProps)
  }

  init = (props=this.props) => {
    if(this.urlBotKey(props))
      this.startChat(this.urlBotKey(props))
  }

  onStartChat = () => {
    if(!this.urlBotKey())
      this.props.history.push('/chat/'+this.props.callerInput) //start chat by navigation
    else
      this.startChat(this.props.callerInput)
  }

  startChat = (botKey) => {
    this.props.actions.startKiwiChat(botKey, {
      isExpanded: true,
      showFeedback: false,
    })
    this.props.actions.set('home', 'callerInput', '')
  }

  render () {
    const {actions, chats, chatCountLimit} = this.props
    // console.log('chats', chats)

    var showBrandContainer = !chatCountLimit || chats.size < chatCountLimit

    return (
      <div className='kiwi-home'>
        <Auth location={this.props.location}/>

        {showBrandContainer &&
          <div className={'brand-container ' + (this.props.chats.size==0 ? 'centered' : 'sidelined')}>

            <div className='brand'>CHaTTy COaCH</div>

            <div className='caller'>
              <input type='text' placeholder='Enter Bot Key'
                onChange={(e) => actions.set('home', 'callerInput', e.target.value)}
                value={this.props.callerInput}/>
              <button className='btn btn-light' onClick={this.onStartChat}>Start Chat</button>
            </div>

            <div className='or'> - OR - </div>

            <a className='btn btn-primary' href={'/bot/create'}>
              Design Your Own Conversation
            </a>
          </div>
        }

        <div className='chat-list'>
          {chats.entrySeq().map(([chatKey, chat]) => {
            // console.log('chatKey', chatKey)
            return (<KiwiChat key={chatKey} chatKey={chatKey} hasClose={true}/>)
          })}
        </div>
        

      </div>
    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    callerInput: state.getIn(['home', 'callerInput']),
    chatCountLimit: state.getIn(['home', 'chatCountLimit']),
    chats: state.getIn(['chats']),
  }),
  (dispatch) => ({ //map dispatch to props
     actions: actions
  })
)(KiwiHome)