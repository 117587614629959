'use strict'

const moment = require('moment')


const FORMAT_ISO = 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ'
const FORMAT_ISO_NO_ZONE = 'YYYY-MM-DD[T]HH:mm:ss.SSS'

module.exports = class TimeOps {
  // static FORMAT_ISO = FORMAT_ISO

  //-------------------------------------------------------------
  
  static nowLocal(offsetMillis=0) {
    return moment(moment.now() + offsetMillis).local()
  }

  static nowUtc(offsetMillis=0) {
    return moment(moment.now() + offsetMillis).utc()
  }

  static nowZone(zoneOffsetHours, offsetMillis=0) {
    return moment(moment.now() + offsetMillis).utcOffset(zoneOffsetHours*60)
  }

  static nowEst(offsetMillis=0) {
    return nowZone(-5)
  }

  static nowMillis(offsetMillis=0) {
    return moment.now() + offsetMillis
  }

  //-------------------------------------------------------------

  static nowTimestampLocal(offsetMillis=0, outputFormat=FORMAT_ISO) {
    return moment(moment.now() + offsetMillis).local().format(outputFormat)
  }

  static nowTimestampUtc(offsetMillis=0, outputFormat=FORMAT_ISO) {
    return moment(moment.now() + offsetMillis).utc().format(outputFormat)
  }

  static nowTimestampZone(zoneOffsetHours, offsetMillis=0, outputFormat=FORMAT_ISO) {
    return moment(moment.now() + offsetMillis).utcOffset(zoneOffsetHours*60).format(outputFormat)
  }

  static nowTimestampEst(offsetMillis=0, outputFormat=FORMAT_ISO) {
    return nowTimestampZone(-5, offsetMillis, outputFormat)
  }

  //-------------------------------------------------------------

  static timestampToMillis(timestamp, format=FORMAT_ISO) {
    return moment(timestamp, format).valueOf()
  }

  //-------------------------------------------------------------

  static millisToLocalTimestamp(millis, outputFormat=FORMAT_ISO) {
    return moment(millis).local().format(outputFormat)
  }

  static millisToUtcTimestamp(millis, outputFormat=FORMAT_ISO) {
    return moment(millis).utc().format(outputFormat)
  }

  static millisToZoneTimestamp(zoneOffsetHours, millis, outputFormat=FORMAT_ISO) {
    return moment(millis).utcOffset(zoneOffsetHours).format(outputFormat)
  }

  static millisToEstTimestamp(zoneOffsetHours, millis, outputFormat=FORMAT_ISO) {
    return millisToZoneTimestamp(-5, millis, outputFormat)
  }

  //-------------------------------------------------------------

  static timestampToLocalTimestamp(timestamp, inputFormat=FORMAT_ISO, outputFormat=FORMAT_ISO) {
    return moment(timestamp, inputFormat).local().format(outputFormat)
  }

  static timestampToUtcTimestamp(timestamp, inputFormat=FORMAT_ISO, outputFormat=FORMAT_ISO) {
    return moment(timestamp, inputFormat).utc().format(outputFormat)
  }

  static timestampToZoneTimestamp(zoneOffsetHours, timestamp, inputFormat=FORMAT_ISO, outputFormat=FORMAT_ISO) {
    return moment(timestamp, inputFormat).utcOffset(zoneOffsetHours*60).format(outputFormat)
  }

  static timestampToEstTimestamp(timestamp, inputFormat=FORMAT_ISO, outputFormat=FORMAT_ISO) {
    return timestampToZoneTimestamp(-5, timestamp)
  }

  //-------------------------------------------------------------

  static parseAsZone(zoneOffsetHours, timestampWithNoZone, inputFormat=FORMAT_ISO_NO_ZONE) {
    //timestampWithNoZone ex. '2018-08-09 09:35:00'
    const sign = zoneOffsetHours<0?'-':'+'
    var hour = Math.abs(zoneOffsetHours)
    if(hour < 10)
      hour = '0' + hour
    const zoneString = `${sign}${hour}:00`
    return moment.parseZone(`${timestampWithNoZone} ${zoneString}`, `${inputFormat} ZZ`)
  }

  static parseAsLocal(timestampWithNoZone, inputFormat=FORMAT_ISO_NO_ZONE) {
    return parseAsZone(LOCAL_OFFSET_HOURS, timestampWithNoZone, inputFormat)
  }

  static parseAsUtc(timestampWithNoZone, inputFormat=FORMAT_ISO_NO_ZONE) {
    return parseAsZone(0, timestampWithNoZone, inputFormat)
  }

  static parseAsEst(timestampWithNoZone, inputFormat=FORMAT_ISO_NO_ZONE) {
    return parseAsZone(-5, timestampWithNoZone, inputFormat)
  }

  //-------------------------------------------------------------

  //keep same timezone
  static timestampToTimestamp(timestamp, inputFormat=FORMAT_ISO, outputFormat=FORMAT_ISO) {
    const m = moment(timestamp, inputFormat)
    m.utcOffset(timestamp) //set timestamp of moment object from original timestamp input
    return m.format(outputFormat)
  }

  //returns offset in milliseconds
  //ex. 32400000 for JST (+0900)
  static getTimestampOffset(timestamp, inputFormat=FORMAT_ISO) {
    const m = moment(timestamp, inputFormat)
    m.utcOffset(timestamp) //set timestamp of moment object from original timestamp input
    return m.utcOffset() * 60000
  }

  //-------------------------------------------------------------

  static timestampToMoment(timestamp, format=FORMAT_ISO) {
    return moment(timestamp, format) //by default is in local timezone
  }

  //data can be millis, moment object, or timestamp (use native moment format deciphering)
  static toLocalDate(data) {
    const copy = moment(data) //copy date
    return copy.local() //modify date timezone and return the new date object
  }

  //data can be millis, moment object, or timestamp (use native moment format deciphering)
  static toUtcDate(data) {
    const copy = moment(data) //copy date
    return copy.utc() //modify date timezone and return the new date object
  }

  //-------------------------------------------------------------

  static toDigMinuteFormat(millis) {
    let seconds = millis / 1000
    let mins = parseInt(seconds / 60)
    let secs = parseInt(seconds % 60)
    return this.padZeros(mins, 2) + ':' + this.padZeros(secs, 2)
  }

  static toDigitalFormat(millis) {
    let seconds = millis / 1000
    let hours = parseInt(seconds / 3600)
    let remaining = seconds % 3600
    let mins = parseInt(remaining / 60)
    let secs = parseInt(seconds % 60)
    let result = ''
    if(hours) result += this.padZeros(hours, 2) + ':'
    result += this.padZeros(mins, 2) + ':' + this.padZeros(secs, 2)
    return result
  }

  static readableDifference(t0, t1) {
    return TimeOps.toDigitalFormat(TimeOps.timestampToMoment(t1) - TimeOps.timestampToMoment(t0))
  }

  static padZeros(data, n) {
    data = data + ''
    while(data.length < n)
      data = '0' + data
    return data
  }

  static timestampToReadable(timestamp, readableFormat='MMMM Do YYYY, h:mm:ss a') {
    var m = TimeOps.timestampToMoment(timestamp)
    m = m.local()
    return m.format(readableFormat)
    // moment().format('MMMM Do YYYY, h:mm:ss a'); // March 12th 2018, 3:53:28 pm
    // moment().format('dddd');                    // Monday
    // moment().format("MMM Do YY");               // Mar 12th 18
    // moment().format('YYYY [escaped] YYYY');     // 2018 escaped 2018
    // moment().format('DD/MM/YYYY HH:mm:ss');     // 26/03/2018 22:17:36
    // moment().format();                          // 2018-03-12T15:55:43+09:00
  }
}
