import React from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import ReactDOM from 'react-dom'

import WS from '../util/WS'
import Voice from '../util/Voice'
import Ops from '../util/Ops'
import Messages from './Messages'

class KiwiChat extends React.Component {
  componentDidMount() {
    
  }

  componentWillUnmount() {

  }

  componentWillReceiveProps(newProps) {
    if(this.props.hasBotVoice) {
      if(newProps.messages.size > this.props.messages.size) {
        var lastMessage = newProps.messages.last()
        if(lastMessage.type == 'bot') {
          Voice.speak(lastMessage.transcript)
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const wasInputBlocked = this.isInputBlocked(prevProps)
    const isInputBlocked = this.isInputBlocked()
    if(this.inputEle && wasInputBlocked && !isInputBlocked)
      this.inputEle.focus()
  }

  onSendMessage = () => {
    Voice.freeze()
    Voice.stopListening()
    this.props.actions.converse(this.props.chatKey)
  }

  onVoice = (e) => {
    var voiceButton = ReactDOM.findDOMNode(this.refs.voiceButton)
    Voice.toggleOn(voiceButton, transcript => {
      this.props.actions.set('chats', this.props.chatKey, 'message', transcript)
    })
  }

  onClose = () => {
    this.props.actions.endKiwiChat(this.props.chatKey)
    if(this.props.onClose) this.props.onClose()
  }

  isInputBlocked = (props=this.props) => {
    if(props.endContext)
      return true

    let job = props.job
    if(!job)
      return false
    job = job.toJS()
    const isJobSpinning = job.state == -1 || job.state == 1
    return isJobSpinning
  }

  render () {
    var {actions, job, endContext, endMessage} = this.props


    var inputBlocked = this.isInputBlocked()//isJobSpinning || !!endContext

    return (
      <div className={'kiwi-chat ' + (this.props.isExpanded?'expanded':'')}>
        <div className='header-section' 
          onClick={() => {if(!this.props.isExpanded)actions.set('chats', this.props.chatKey, 'isExpanded', true)}}>

          <div className='header-title'>
            <span className='fa fa-comments'></span>
            {this.props.isExpanded && <span>{this.props.bot.get('name') || this.props.botKey}</span>}
          </div>
          
          <div className='flow1'/>

          {this.props.isExpanded && (<div>
            <button
              className={'btn btn-light btn-sm no-outline '}
              onClick={() => actions.toggle('chats', this.props.chatKey, 'hasBotVoice')}>
              <span className={"fa " + (this.props.hasBotVoice?"fa-volume-up":"fa-volume-off")}></span>
            </button>
            <button
              className={'btn btn-light btn-sm no-outline '}
              onClick={() => actions.resetKiwiChat(this.props.chatKey)}>
              <span className="fa fa-refresh"></span>
            </button>
            {this.props.hasMinimize && (
              <button
                className={'btn btn-light btn-sm no-outline '}
                onClick={() => actions.set('chats', this.props.chatKey, 'isExpanded', false)}>
                <span className="fa fa-minus"></span>
              </button>
            )}
            {this.props.hasClose && (
              <button
                className={'btn btn-light btn-sm no-outline '}
                onClick={this.onClose}>
                <span className="fa fa-remove"></span>
              </button>
            )}
          </div>)}
        </div>

        {this.props.isExpanded && <Messages 
          messages={this.props.messages} 
          job={this.props.job} 
          description={this.props.bot.get('description')}
          showFeedback={this.props.showFeedback}
          endContext={this.props.endContext}
          endMessage={this.props.endMessage}
          characterIcon={this.props.bot.get('characterIcon')}
          />
        }

        {this.props.isExpanded && (
          <div className='input-section'>
            
            <input id='message-input' className="form-control input" type="text"
              ref={r => this.inputEle = r} 
              value={this.props.message}
              placeholder='Type a message...'
              onChange={(e) => actions.set('chats', this.props.chatKey, 'message', e.target.value)}
              onKeyPress={(e) => {if(e.which==13) this.onSendMessage()}}
              disabled={inputBlocked}
              />
            <button className="btn btn-primary" 
              onClick={this.onSendMessage}
              disabled={inputBlocked}>
              <span className="fa fa-arrow-right"></span>
            </button>

            {this.props.renderVoiceControls && (
              <button ref='voiceButton' className="btn btn-light" 
                onClick={this.onVoice}
                disabled={inputBlocked}>
                <span className="fa fa-microphone"></span>
              </button>
            )}
            <button onClick={() => actions.set('chat', 'message', '')}  type="button" className="btn btn-light" style={{display:'none'}}>
              <span className="fa fa-remove"></span>
            </button>
              
          </div>
        )}

      </div>
    )
  }
}

KiwiChat.defaultProps = {
  hasMinimize: false,
  hasClose: false,
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state, props) => {
    const chat = state.getIn(['chats', props.chatKey])
    return {
      botKey: chat.get('botKey'),
      messages: chat.get('messages'),
      message: chat.get('message'),
      confidence: chat.get('confidence'),
      showFeedback: chat.get('showFeedback'),
      job: chat.get('job'),
      isExpanded: chat.get('isExpanded'),
      hasBotVoice: chat.get('hasBotVoice'),
      renderVoiceControls: state.get('renderVoiceControls'),
      endContext: chat.get('endContext'),
      endMessage: chat.get('endMessage'),
      bot: chat.get('bot'),
    }
  },
  (dispatch) => ({ //map dispatch to props
     actions: actions
  })
)(KiwiChat)