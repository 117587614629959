import React from 'react'
import axios from 'axios'
const fabric = require('fabric').fabric

import Nav from './Nav'
import KiwiChat from './KiwiChat'
import Ops from '../util/Ops'
import Sketch from './sketch/Sketch'
import Tools from './sketch/tools/tools'
import XLSX from '../util/XLSX'
import config from '../config'
import sample0 from './sketch/samples/sample0'

var dataJson = null
const DEFAULT_TOOL = Tools.Select

class SketchPage extends React.Component {

  botKey = () => this.props.match.params.botKey

  state = {
    lineColor: 'black',
    lineWidth: 4,
    fillColor: '#68CCCA',
    backgroundColor: 'transparent',
    shadowWidth: 0,
    shadowOffset: 0,
    tool: DEFAULT_TOOL,
    fillWithColor: false,
    fillWithBackgroundColor: false,
    drawings: [],
    canUndo: false,
    canRedo: false,
    controlledSize: false,
    sketchWidth: 600,
    sketchHeight: 600,
    stretched: true,
    stretchedX: false,
    stretchedY: false,
    originX: 'left',
    originY: 'top',
    sidebarTab: 0,
  }

  componentDidMount() {
    this.props.actions.connectKiwiSketch(this.botKey())

    var chatKey = this.props.actions.startKiwiChat(this.botKey(), {
      showFeedback: true,
      isExpanded: false,
      persist: true, // originally persist was false, i made it persist to save and calculate the comprehension and accuracy grades on the server side 
    })
    this.props.actions.set('sketch', 'chatKey', chatKey)

    this._sketch.setBearing(this.props.kiwiContext&&this.props.kiwiContext.get('bearing'))


    window.addEventListener('keydown', this._onKeyDown)
    window.addEventListener('keyup', this._onKeyUp)
  }

  componentWillUnmount() {
    this.props.actions.endKiwiChat(this.props.chatKey)
    this.props.actions.disconnectKiwiSketch()

    window.removeEventListener('keydown', this._onKeyDown)
    window.removeEventListener('keyup', this._onKeyUp)
  }

  _onKeyDown = (e) => {
    // console.log('_onKeyDown', e)
    if(!(e.target == document.body)) //not focusing another input
      return

    if(e.key.toLowerCase() == ' ') {
      if(this.state.tool != Tools.Pan)
        this.setState({tool:Tools.Pan})
    }
  }

  _onKeyUp = (e) => {
    if(!(e.target == document.body)) //not focusing another input
      return

    if(e.key.toLowerCase() == ' ') {
      this.setState({tool:Tools.Select})
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.cellData != nextProps.cellData) {
      // console.log('cellData', nextProps.cellData)
      this._sketch.fromJSON(nextProps.cellData.toJS(), () => {
        this._sketch.setBearing(nextProps.kiwiContext&&nextProps.kiwiContext.get('bearing'))
      })
    }

    if(this.props.kiwiContext != nextProps.kiwiContext)
      this._sketch.setBearing(nextProps.kiwiContext&&nextProps.kiwiContext.get('bearing'))

    if(nextProps.job && this.props.job != nextProps.job && !this.didAddSampleCells) {
      var job = nextProps.job.toJS()
      if(!this.didAddSampleCells && job.name == 'prepare' && job.state == 3) {
        this._sketch.fromJSON(sample0)
        this.didAddSampleCells = true
      }
    }
  }

  _onSketchChange = (o) => {
    var canvas = this._sketch._fc
    const activeObject = canvas.getActiveObject()
    // const activeSelection = canvas.getActiveSelection()

    // if(activeObject) {
      // console.log('activeObject', activeObject?activeObject.type:'', activeObject)
      // activeObject.set({label: '1234', dirty: true})
      // canvas.renderAll()
      this.setState({selectedCell: activeObject})
    // }
  }

  _onChangeNature = (e, propertyName) => {
    // console.log(e.target.value, e.target.checked, propertyName)
    var node = this.state.selectedCell
    var nature = node.nature
    if(propertyName == 'bearingFree')
      nature[propertyName] = e.target.checked
    else
      nature[propertyName] = e.target.value
    node.setNature(nature)
    this.forceUpdate()
  }

  _onChangePattern = (pattern, index) => {
    var node = this.state.selectedCell
    var nature = node.nature
    nature.patterns[index] = pattern
    node.setNature(nature)
    this.forceUpdate()
  }

  _onAddPattern = (index) => {
    var node = this.state.selectedCell
    var nature = node.nature
    nature.patterns.splice(index+1,0,'')
    node.setNature(nature)
    this.forceUpdate()
  }

  _onRemovePattern = (index) => {
    var node = this.state.selectedCell
    var nature = node.nature
    nature.patterns.splice(index,1)
    node.setNature(nature)
    this.forceUpdate()
  }

  onDownload = () => {
    Ops.downloadJSON(this._sketch.toJSON(), 'cell_data.json')
  }

  onUpload = (event) => {
    // console.log('onUpload()')
    var reader = new FileReader()
    reader.onload = (e) => {
      var json = JSON.parse(e.target.result)
      // console.log(json)
      // this.props.actions.set('chat', 'cellData', json)
      this._sketch.fromJSON(json)
    }
    reader.readAsText(event.target.files[0])
    event.target.value = null //reset input
  }

  onUploadSheet = (event) => {
    var file = event.target.files[0]
    XLSX.addCells(this._sketch, file)
  }

  onLog = () => {
    window.cellData = this._sketch.toJSON()
    console.log(window.cellData)
  }

  onTrain = () => {
    var cellData = this._sketch.toJSON()
    this.props.actions.trainKiwi(this.botKey(), cellData)
  }

  onUploadCharacterIcon = (event) => {
    var actions = this.props.actions
    const file = event.target.files[0]
    if(file.type.indexOf('image') == -1) {
      alert('File must be an image.')
      return
    }
    var reader = new FileReader()
    reader.onload = (e) => {
      // console.log('e.target.result', e.target.result)
      const base64Image = btoa(e.target.result)

      var image = new Image()
      image.src = `data:image/jpeg;base64,${base64Image}`
      image.onload = function() {
        if(this.width != 64 || this.height != 64) {
          alert('Image must be 64x64')
          return
        }

        // console.log('base64Image', base64Image.length)
        actions.set('sketch', 'bot', 'characterIcon', base64Image)
      }
    }
    reader.readAsBinaryString(event.target.files[0])
    event.target.value = null //reset input
  }

  onSave = () => {
    var bot = this.props.bot.toJS()
    console.log('onSave', bot)
    axios.post('/bot', {bot})
      .then(res => {
        console.log('res.data', res.data)
        var bot = res.data.bot
        // var img = document.createElement('img')
        // img.src = 'data:image/jpeg;base64,' + btoa(bot.characterIcon)
        // document.body.appendChild(img)
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    if(this.props.job) {
      var job = this.props.job.toJS()
      var isJobSpinning = job.state == -1 || job.state == 1
      var jobMessage = job.stateMap[job.state]
      var isPreparing = job.name == 'prepare' && isJobSpinning
    }

    return (
      <div className='sketch-page'>

        <Nav/>

        <div className='hbox flow1 rel'>

          <div className='sketch-area'>

            <Sketch
                name='sketch'
                ref={(c) => this._sketch = c}
                lineColor={this.state.lineColor}
                lineWidth={this.state.lineWidth}
                fillColor={this.state.fillWithColor ? this.state.fillColor : 'transparent'}
                backgroundColor={this.state.fillWithBackgroundColor ? this.state.backgroundColor : 'transparent'}
                width={this.state.controlledSize ? this.state.sketchWidth : null}
                height={this.state.controlledSize ? this.state.sketchHeight : null}
                defaultValue={dataJson}
                value={null}
                forceValue={true}
                onChange={this._onSketchChange}
                tool={this.state.tool}
                // style={{border: '2px solid black'}}
            />

            {!isPreparing && this.renderEditor()}

          </div>

          {!isPreparing && this.renderSidebar(job, isJobSpinning, jobMessage)}
          {!isPreparing && this.props.chatKey && <KiwiChat chatKey={this.props.chatKey} hasMinimize={true}/>}
          {isPreparing && <div className='trispinner-cover'><div><span/></div><span>{jobMessage}</span></div>}

        </div>

      </div>
    )
  }

  renderEditor = () => {
    var {selectedCell} = this.state

    if(!selectedCell || !selectedCell.isNatureEditable)
      return null

    return (
      <div className='sketch-editor-container'>
        <div className='sketch-editor'>

          <div className='cell-type'>{selectedCell.type}</div>

          {(selectedCell.type == 'HumanNode' || selectedCell.type == 'BotNode') &&
            [
            selectedCell.nature.patterns.map((pattern,i) => (
              <div className={selectedCell.type}>
                <textarea value={pattern}
                  onChange={(e) => this._onChangePattern(e.target.value, i)}
                  placeholder={'Enter a phrase'}
                  title={'Variables can be added as words surrounded by square brackets ex. [X]'}
                  />
                <button className='btn btn-secondary'
                  onClick={() => this._onRemovePattern(i)}
                  title='Remove phrase'>
                  <span className='fa fa-minus'/>
                </button>
                <button className={'btn btn-secondary ' + (selectedCell.nature.patterns.length==10&&' disabled ')}
                  onClick={() => selectedCell.nature.patterns.length<10&&this._onAddPattern(i)}
                  title='Add phrase'>
                  <span className='fa fa-plus'/>
                </button>
              </div>
            )),
            selectedCell.nature.patterns.length == 0 && (
              <button className='btn btn-secondary'
                onClick={() => this._onAddPattern(-1)}>
                <span className='fa fa-plus'/>
              </button>
            )
            ]
          }

          {(selectedCell.type == 'HumanNode') && 
            <div className='bearing-required'>
              <span>Context Free</span>
              <input type='checkbox' 
                checked={selectedCell.nature.bearingFree || false}
                onChange={(e) => this._onChangeNature(e, 'bearingFree')}
                />
            </div>
          }

          {(selectedCell.type == 'ConditionNode') &&
            <div className={selectedCell.type}>
              <input className='name' type='text' placeholder='[x]'
                value={selectedCell.nature.name} 
                onChange={(e) => this._onChangeNature(e, 'name')} />
              <select className='operation'
                value={selectedCell.nature.operation}
                onChange={(e) => this._onChangeNature(e, 'operation')}>
                <option>==</option>
                <option>&gt;=</option>
                <option>&gt;</option>
                <option>&lt;=</option>
                <option>&lt;</option>
                <option>exists</option>
              </select>
              <input className='value' type='text' placeholder='value'
                value={selectedCell.nature.value} 
                onChange={(e) => this._onChangeNature(e, 'value')} />
            </div>
          }

          {(selectedCell.type == 'ParameterNode') &&
            <div className={selectedCell.type}>
              <input className='name' type='text' placeholder='[x]'
                value={selectedCell.nature.name} 
                onChange={(e) => this._onChangeNature(e, 'name')} />
              <select className='operation'
                value={selectedCell.nature.operation}
                onChange={(e) => this._onChangeNature(e, 'operation')}>
                <option>=</option>
                <option>+</option>
                <option>-</option>
              </select>
              <input className='value' type='text' placeholder='value'
                value={selectedCell.nature.value} 
                onChange={(e) => this._onChangeNature(e, 'value')} />
            </div>
          }

          {(selectedCell.type == 'EndNode') &&
            <div className={selectedCell.type}>
              <textarea 
                value={selectedCell.nature.message}
                onChange={(e) => this._onChangeNature(e, 'message')}
                placeholder='Add a conversation ending message for the user'
                />
            </div>
          }

        </div>
      </div>
    )
  }

  renderSidebar = (job, isJobSpinning, jobMessage) => {
    var isTrainingDisabled = isJobSpinning

    return (
      <div className='sketch-sidebar'>
        <div className='tabs'>
          <span className={'tab-item '+(this.state.sidebarTab==0?' active ':'')} onClick={() => this.setState({sidebarTab:0})}>Tools</span>
          <span className={'tab-item '+(this.state.sidebarTab==1?' active ':'')} onClick={() => this.setState({sidebarTab:1})}>Settings</span>
        </div>

        {this.state.sidebarTab == 0 && this.renderSidebarTools(job, isJobSpinning, jobMessage)}
        {this.state.sidebarTab == 1 && this.renderSidebarSettings(job, isJobSpinning, jobMessage)}

      </div>
    )
  }
  renderSidebarTools = (job, isJobSpinning, jobMessage) => {
    var isTrainingDisabled = isJobSpinning

    return (
      <div className='sketch-tools'>
        <div>Tools</div>
        <div>
          <button className={'btn btn-tool ' + (this.state.tool==Tools.Select?'active':'')}
            onClick={() => this.setState({tool:Tools.Select})} title='Select'>
            <i className='fa fa-mouse-pointer'></i>
          </button>

          <button className={'btn btn-tool ' + (this.state.tool==Tools.Pan?'active':'')}
            onClick={() => this.setState({tool:Tools.Pan})} title='Pan (space)'>
            <i className='fa fa-arrows'/>
          </button>
        </div>
        <br/>

        <div>Functions</div>
        <div>
          <button className='btn btn-function' onClick={() => this._sketch.undo()} title='Undo'>
            <i className='fa fa-undo'></i>
          </button>
          <button className='btn btn-function' onClick={() => this._sketch.redo()} title='Redo'>
            <i className='fa fa-repeat'></i>
          </button>
          <br/>
          {/*
          <button className='btn btn-function' onClick={() => this._sketch.zoomBy(0.5)}>zoom out (0.5x)</button>
          <button className='btn btn-function' onClick={() => this._sketch.zoomBy(2.0)}>zoom in (2.0x)</button>
          */}

          <button className='btn btn-function' onClick={() => this._sketch.copy()} title='Copy'>
            <i className='fa fa-copy'></i>
          </button>
          <button className='btn btn-function' onClick={() => this._sketch.paste()} title='Paste'>
            <i className='fa fa-paste'></i>
          </button>
          <br/>

          <button className='btn btn-function' onClick={this.onDownload} title='Download Sketch Data'>
            <i className='fa fa-download'></i>
          </button>

          <label htmlFor='json-input' className='btn btn-function m0' title='Upload Sketch Data'>
            <i className='fa fa-upload'></i>
          </label>
          <input id='json-input' className='file-input' type='file' onChange={this.onUpload}/>

          <label htmlFor='sheet-input' className='btn btn-function m0' title='Upload Spreadsheet'>
            <i className='fa fa-table'></i>
          </label>
          <input id='sheet-input' className='file-input' type='file' onChange={this.onUploadSheet}/>

          <br/>
          <button className='btn btn-function' onClick={() => this._sketch.clear()} title='Clear Sketch'>
            <i className='fa fa-trash-o'></i>
          </button>
          <button className='btn btn-function' onClick={() => Ops.openNewTab('/chat/'+this.botKey())} title='Open Chat'>
            <i className='fa fa-comment'></i>
          </button>
          {/*
              <button className='btn btn-function' onClick={() => this._sketch.deleteActiveObject()}>delete</button>
          */}
        </div>

        <br/>
        <div>Cells</div>
        <div className='cell-btn-group btn-group-vertical'>
          <button className={'btn btn-human'}
            onClick={() => this._sketch.addNewCell(fabric.HumanNode)}
            title='Define some phrases the user can say'>
            Human Node
          </button>
          <button className={'btn btn-bot'}
            onClick={() => this._sketch.addNewCell(fabric.BotNode)}
            title='Define some phrases the bot can say'>
            Bot Node
          </button>
          <button className={'btn btn-condition'}
            onClick={() => this._sketch.addNewCell(fabric.ConditionNode)}
            title='Add a condition branch, circle port is pass, square is fail'>
            Condition Node
          </button>
          <button className={'btn btn-parameter'}
            onClick={() => this._sketch.addNewCell(fabric.ParameterNode)}
            title='Define and set some variables to be used later on'>
            Parameter Node
          </button>
          <button className={'btn btn-end'}
            onClick={() => this._sketch.addNewCell(fabric.EndNode)}>
            End Node
          </button>
        </div>
        
        <br/>
        {/*
          <button className='btn btn-default' onClick={() => this.onLog()}>log</button>
        */}

        <div className='flow1'/>

        <br/>
        <div className='dashboard-job'>
          <div className={'mind'}>
            {job && (
              <div className={job.name}>
                {isJobSpinning && <div className='trispinner-wrapper'> <span className='trispinner trispinner-white'></span></div>}
                <span>{jobMessage}</span>
              </div>
            )}
          </div>
        </div>

        <button className='btn btn-train' 
          onClick={() => this.onTrain()} title='Save Changes'
          disabled={isTrainingDisabled}>
          Train
        </button>
      </div>
    )
  }

  renderSidebarSettings = (job, isJobSpinning, jobMessage) => {
    return (
      <div className='sketch-settings'>
        <div>Tolerance</div>
        <input type='number' step='0.05' min='0.0' max='1.0'
          value={this.props.bot.get('tolerance') || ''}
          onChange={(e) => this.props.actions.set('sketch', 'bot', 'tolerance', parseFloat(e.target.value))}
          placeholder='Enter a matching tolerance'
          title='How close the input is required to match the human node patterns (higher means requires closer)'/>
        <div>Name</div>
        <input value={this.props.bot.get('name') || ''}
          onChange={(e) => this.props.actions.set('sketch', 'bot', 'name', e.target.value)}
          placeholder='Enter a name'
          />
        <div>Description</div>
        <textarea value={this.props.bot.get('description') || ''}
          onChange={(e) => this.props.actions.set('sketch', 'bot', 'description', e.target.value)}
          placeholder='Enter a description'
          title='Give the student an objective to achieve in the roleplay'/>
        <div>No Match Response</div>
        <textarea value={this.props.bot.get('noMatchResponse') || ''}
          onChange={(e) => this.props.actions.set('sketch', 'bot', 'noMatchResponse', e.target.value)}
          placeholder='Enter the no match response message'
          title='The bot response when the user input is not recognized'/>
        <div>Sharing</div>
        <select value={this.props.bot.get('sharing')}
          onChange={(e) => this.props.actions.set('sketch', 'bot', 'sharing', parseInt(e.target.value))}
          title='Privacy Settings'>
          <option value={0}>Private (Only Me)</option>
          <option value={1}>Others can view</option>
          <option value={2}>Others can edit</option>
        </select>

        <div>Character Icon</div>
        <div className='character-icon'>
          <img src={ this.props.bot.get('characterIcon')
            ? `data:image/jpeg;base64,${this.props.bot.get('characterIcon')}`
            : config.defaultCharacterIcon
          }/>
          <label htmlFor='character-icon-input' className='btn btn-function m0' title='Upload Character Icon (64x64)'>
            <i className='fa fa-upload'></i>
          </label>
          <input id='character-icon-input' className='file-input' type='file' onChange={this.onUploadCharacterIcon}/>
          <button className={'btn btn-light'}
            onClick={() => this.props.actions.set('sketch', 'bot', 'characterIcon', null)} title='Remove Icon'>
            <i className='fa fa-remove'></i>
          </button>
        </div>

        <br/>
        <div className='btn btn-primary' onClick={this.onSave}>Save</div>
      </div>
    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state, props) => ({
    cellData: state.getIn(['sketch', 'cellData']),
    job: state.getIn(['sketch', 'job']),
    chatKey: state.getIn(['sketch', 'chatKey']),
    kiwiContext: state.getIn(['sketch', 'kiwiContext']),
    bot: state.getIn(['sketch', 'bot']),
  }),
  (dispatch) => ({ //map dispatch to props
     actions: actions,
  })
)(SketchPage)