import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import * as Store from './redux/store'
import reducer from './redux/reducer'
import * as actions from './redux/actions'
import Router from './components/Router'

//init jQuery and bootstrap js
window.$ = window.jQuery = require('jquery')
require('jquery.easing')
require('bootstrap/dist/js/bootstrap.bundle.js')

const store = Store.create(reducer)
actions.init()

class App extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <Router/>
      </Provider>
    )
  }
}

ReactDOM.render(
  <App/>,
  document.getElementsByClassName('body-content')[0]
)