var setupProto = () => {
  var proto = fabric.LinkLine.prototype
  proto.defaultOptions = () => ({
    fill: false,
    stroke: '#212121',
    strokeWidth: 2,

    hasControls: false,
    hasBorders: false,
    // lockMovementX: true,
    // lockMovementY: true,
    // hoverCursor: 'default',

    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,

    link: null,
  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = false
  proto.shouldPersist = false
  proto.isNatureEditable = false
}

fabric.LinkLine = fabric.util.createClass(fabric.Line, {
  type: 'LinkLine',
  baseType: 'LinkLine',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', [options.x1, options.y1, options.x2, options.y2], options)
  },

  //-FUNCTIONS---------------------------------------------------------------------
  split: function() {
    this.link.splitLine(this)
  },

  //-EVENTS---------------------------------------------------------------------
  onAdded: function() {
    this.sendToBack()
  },

  onRemoved: function() {
    if(this.splitControl){
      this.splitControl.remove()
      this.splitControl = null
    }
  },

  onCanvasMouseMove: function(pointer) {
    // const isHovered = o.containsPoint(pointer)
    // console.log('LinkLine.onCanvasMouseMove', isHovered)
    const pointerDistance = this.getaCenterPoint().distanceFrom(pointer)
    if(pointerDistance < 40) {
      if(!this.splitControl) {
        this.splitControl = new fabric.LinkSplitControl({line: this})
        this.canvas.add(this.splitControl)
        this.updateAttached()
        this.canvas.renderAll()
      }
    }
    else {
      if(this.splitControl){
        this.splitControl.remove()
        this.splitControl = null
      }
    }
  },

  //-INTERNAL---------------------------------------------------------------------
  setCoords: function(...args) {
    this.callSuper('setCoords', ...args);
    this.updateAttached()
  },

  updateAttached: function(){
    if(this.splitControl) this.splitControl.setaPos(this.getaCenterPoint())
  },
  
})
setupProto()