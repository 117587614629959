var setupProto = () => {
  var proto = fabric.Port.prototype
  proto.defaultOptions = () => ({
    radius: 14,
    strokeWidth: 2,
    fill: '#FFFF00ee',
    stroke: '#e0e0e0',

    shadow:{ 
      color: 'rgba(0,0,0,0.3)',
      offsetX: 3,
      offsetY: 3,
    },

    node: null,
    hooks: [],
  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = true
  proto.shouldPersist = true
  proto.isNatureEditable = false
}

fabric.Port = fabric.util.createClass(fabric.Circle, {
  type: 'Port',
  baseType: 'Port',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },

  //-SERIALIZATION----------------------------------------------------------------
  toObject: function() {
    // return fabric.util.object.extend(this.callSuper('toObject'), {
    //   id: this.get('id'),
    //   baseType: this.get('baseType'),
    //   nodeId: this.get('node') ? this.get('node')['id'] : null,
    //   hookIds: this.hooks.map(o => o.id),
    // });

    return {
      id: this.id,
      type: this.type,
      baseType: this.baseType,

      left: this.left,
      top: this.top,
      
      nodeId: this.node.id,
      hookIds: this.hooks.map(o => o.id),
    }
  },

  revive: function(allObjects) {
    // console.log('this.nodeId', this.nodeId)
    this.node = allObjects.find((o) => o.id == this.nodeId)
    delete this.nodeId
    // console.log('port revive:: found: ', this.node)

    // console.log('this.hookIds', this.hookIds)
    this.hooks = allObjects.filter((o) => this.hookIds.includes(o.id))
    delete this.hookIds
    // console.log('this.hooks', this.hooks)
  },

  //-FUNCTIONS----------------------------------------------------------------
  attachHook: function(hook) {
    var port = this
    if(hook.port == port)
      return //already attached

    var _preLen = port.hooks.length
    hook.port = port
    port.hooks.push(hook)
    port.setCoords() //should update hook position
    // console.log('Port::attachHook(): numHooks: ' + _preLen + ' -> ' + port.hooks.length)
  },

  detachHook: function(hook) {
    var port = this
    var _preLen = port.hooks.length

    var i = port.hooks.indexOf(hook)
    if(i != -1)
      port.hooks.splice(i,1)

    hook.port = null
    // console.log('Port::detachHook(): numHooks: ' + _preLen + ' -> ' + port.hooks.length)
  },

  getaHookPoint: function() {}, //set in subclasses

  //-EVENTS----------------------------------------------------------------
  onRemoved: function() {
    for(var hook of this.hooks) {
      this.canvas.remove(hook)
    }
  },

  //-INTERNAL----------------------------------------------------------------
  setCoords: function(...args) { //after position set programatically
    this.callSuper('setCoords', ...args)
    this.updateAttached()
  },

  updateAttached: function() {
    // console.log(this.type + ': updateAttached', this.hooks)
    for(var hook of this.hooks) {
      hook.setaPos(this.getaHookPoint())
    }
  },

})
setupProto()


//-----------------------------------------------------------------------------
fabric.InPort = fabric.util.createClass(fabric.Port, {
  type: 'InPort',
  baseType: 'Port',

  getaHookPoint: function() {
    return this.getaLeftPoint()
  },

})

//-----------------------------------------------------------------------------
fabric.OutPort = fabric.util.createClass(fabric.Port, {
  type: 'OutPort',
  baseType: 'Port',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      hoverCursor: 'crosshair',
    }, options))
  },

  getaHookPoint: function() {
    return this.getaRightPoint()
  },

})

//-----------------------------------------------------------------------------
fabric.PassPort = fabric.util.createClass(fabric.OutPort, {
  type: 'PassPort',
  baseType: 'Port',
})

//-----------------------------------------------------------------------------
fabric.FailPort = fabric.util.createClass(fabric.OutPort, {
  type: 'FailPort',
  baseType: 'Port',

  onAdded: function() {
    this.failRect = new fabric.PortFailRect({
      width: this.width,
      height: this.height,
      strokeWidth: this.strokeWidth,
      fill: this.fill,
      stroke: this.stroke,
      shadow: this.shadow,
    })
    this.canvas.add(this.failRect)
    this.updateAttached()
  },

  onRemoved: function() {
    if(this.failRect) {
      this.failRect.remove()
    }
  },

  updateAttached: function() {
    this.callSuper('updateAttached')
    if(this.failRect) this.failRect.setaPos(this.getaCenterPoint())
  },
})