import React from 'react'
import Auth from './Auth'

class AuthPage extends React.Component {
  componentDidMount() {
    this.checkAuthStatus(this.props)
  }

  componentWillReceiveProps(newProps) {
    this.checkAuthStatus(newProps)
  }

  checkAuthStatus = (props) => {
    if(props.user) {
      // console.log(props, this.context.router)
      this.context.router.history.replace('/')
    }
  }

  render () {
    return (
      <div className='auth-page'>
        <Auth location={this.props.location}/>
      </div>
    )
  }
}

AuthPage.contextTypes = {
  router: React.PropTypes.shape({
    history: React.PropTypes.object.isRequired,
  }),
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    user: state.get('user'),
  }),
  (dispatch) => ({ //map dispatch to props
     actions: actions,
  })
)(AuthPage)