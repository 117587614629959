import React from 'react'
import ReactDOM from 'react-dom'
import Ops from '../util/Ops'
import Voice from '../util/Voice'
import TimeOps from '../../../commons/TimeOps'
import config from '../config'

class Messages extends React.Component {

  componentDidUpdate() {
    var messagesSection = ReactDOM.findDOMNode(this.refs.messagesSection)
    if(messagesSection)
      $(messagesSection).scrollTop(messagesSection.scrollHeight)
  }

  render() {
    var {messages, job, description, showFeedback, endContext, endMessage} = this.props
    messages = messages.toJS ? messages.toJS() : messages
    endContext = (endContext && endContext.toJS) ? endContext.toJS() : endContext
    endMessage = endMessage || 'The conversation has ended'

    const humanMessages = messages.filter(m => m.type == 'human')
    const lastHumanMessage = humanMessages.length > 0 ? humanMessages[humanMessages.length-1] : null

    if(job) {
      job = job.toJS()
      var isJobSpinning = job.state == -1 || job.state == 1
      // console.log('job', job)
      var jobMessage = job.stateMap[job.state]
    }

    var endContextMemoryString = ''
    //if(endContext) {
      //for(var varName in endContext.memory)
      //  endContextMemoryString += varName + ': ' + endContext.memory[varName] + '\n'
    //}

    return(
      <div className='messages-section' ref='messagesSection' >

        {description && <div className='bot-description'> {description} </div>}

        {messages.map((message, i) => {
          var responseDelay = null
          if(i != 0) {
            var t0 = messages[i-1].timestamp
            var t1 = message.timestamp
            responseDelay = TimeOps.readableDifference(t0, t1)
          }

          var memory = message.kiwiContext && message.kiwiContext.memory
          var memoryString = ''
          if(memory) {
            for(var varName in memory)
              memoryString += varName + ': ' + memory[varName] + '\n'
          }

          if(message.type == 'human') {
            return ([
              <div className='user-row' key={i+'0'}>
                <span className='user-icon'></span>
                <span className='user-message' onClick={() => Voice.speak(message.transcript)}>{message.transcript}</span>
              </div>,
              <div className='feedback-row-human' key={i+'1'}>
                {showFeedback && responseDelay && <span className='feedback-message'>
                  {'Response Delay: ' + responseDelay}
                </span>}
                {showFeedback && Ops.isNum(message.score) && <span className='feedback-score'>{'Score: ' + Math.round(message.score*100.0) + '%'}</span>}
                {/*Ops.isNum(message.tolerance) && <span className='feedback-score'>{'Tolerance: ' + message.tolerance}</span>*/}
                {message.nodePatterns && <span className='feedback-patterns'>{'Expected: ' + message.nodePatterns}</span>}
                {/*message.didPass && <span className='feedback-score'>{'didPass: ' + message.didPass}</span>*/}
                {/*Ops.isNum(message.comprehensionGrade) && <span className='feedback-score'>{'comprehensionGrade: ' + message.comprehensionGrade}</span>*/}
                {/*Ops.isNum(message.accuracyGrade) && <span className='feedback-score'>{'accuracyGrade: ' + message.accuracyGrade}</span>*/}
                {/*memoryString && <span className='feedback-message'>{memoryString}</span>*/}
              </div>
            ])
          }
          else if(message.type == 'bot') {
            return ([
              <div className='bot-row' key={i+'0'}>
                <img className='bot-icon' src={ this.props.characterIcon
                  ? `data:image/jpeg;base64,${this.props.characterIcon}`
                  : config.defaultCharacterIcon
                }/>
                <span className='bot-message' onClick={() => Voice.speak(message.transcript)}>{message.transcript}</span>
              </div>,
              showFeedback &&
                <div className='feedback-row-bot' key={i+'1'}>
                  {responseDelay && <span className='feedback-message'>
                    {'Response Delay: ' + responseDelay}
                  </span>}
                  {memoryString && <span className='feedback-message'>{memoryString}</span>}
                </div>
            ])
          }
        })}

        {endContext && <div className='conversation-summary'>
          <div className='conversation-summary-message'>{endMessage}</div>
          <div className='conversation-score'>
            {lastHumanMessage && <div>{
              `Comprehension Grade: ${Math.round(lastHumanMessage.comprehensionGrade*100.0)}%`
            }</div>}
            {lastHumanMessage && <div>{
              `Accuracy Grade: ${Math.round(lastHumanMessage.accuracyGrade*100.0)}%`
            }</div>}
          </div>
          {endContextMemoryString && <div className='end-context'>{endContextMemoryString}</div>}
        </div>}

        {isJobSpinning && (
          <div className={'mind'}>
            {job && (
              <div className={job.name}>
                {isJobSpinning && <div className='trispinner-wrapper'> <span className='trispinner trispinner-white'></span></div>}
                <span>{jobMessage}</span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

}
export default Messages