var setupProto = () => {
  var proto = fabric.HookRemoveControl.prototype
  proto.defaultOptions = () => ({
    fill: 'purple',
    radius: 4,
    hoverCursor: 'pointer',

    hook: null,
  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = true
  proto.shouldPersist = false
  proto.isNatureEditable = false
}

fabric.HookRemoveControl = fabric.util.createClass(fabric.Circle, {
  type: 'HookRemoveControl',
  baseType: 'HookRemoveControl',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },

  onMouseDown: function(o) {
    this.hook.remove()
  },

})
setupProto()
