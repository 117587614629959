var setupProto = () => {
  var proto = fabric.Node.prototype
  proto.defaultOptions = () => ({
    width: 100,
    height: 80,
    stroke: '#444',
    strokeWidth: 2,

    hasControls: false,
    hasBorders: true,
    borderScaleFactor: 4,
    // selectionBackgroundColor: '#00000000',

    shadow:{ 
      color: 'rgba(0,0,0,0.3)',
      offsetX: 3,
      offsetY: 3,
    },

    nature: null,
  })

  proto.isDependant = false
  proto.isSelectable = true
  proto.isEventable = true
  proto.shouldPersist = true
  proto.isNatureEditable = false
}

fabric.Node = fabric.util.createClass(fabric.Rect, {
  type: 'Node',
  baseType: 'Node',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },

  //-SERIALIZATION-------------------------------------------------------------------
  toObject: function() {
    var output = {
      id: this.id,
      type: this.type,
      baseType: this.baseType,
      nature: this.nature,

      left: this.left,
      top: this.top,
    }

    if(this.inPort) output['inPortId'] = this.inPort.id
    if(this.outPort) output['outPortId'] = this.outPort.id
    if(this.passPort) output['passPortId'] = this.passPort.id
    if(this.failPort) output['failPortId'] = this.failPort.id
      
    return output
  },

  revive: function(allObjects) {
    // console.log('node revive:: found: ', inPort)
    if(this.inPortId)
      this.inPort = allObjects.find((o) => o.id == this.inPortId)
    delete this.inPortId
    if(this.outPortId)
      this.outPort = allObjects.find((o) => o.id == this.outPortId)
    delete this.outPortId
    if(this.passPortId)
      this.passPort = allObjects.find((o) => o.id == this.passPortId)
    delete this.passPortId
    if(this.failPortId)
      this.failPort = allObjects.find((o) => o.id == this.failPortId)
    delete this.failPortId
  },

  clone: function() {
    return new fabric[this.type]({
      left: this.left,
      top: this.top,

      nature: JSON.parse(JSON.stringify(this.nature)),
    })
  },

  //-FUNCTIONS-------------------------------------------------------------------
  getLabel: function() { return this.type },

  setNature: function(nature) {
    this.set({nature: nature})
    this.updateNatureText()
  },

  setBearingStyle: function(flag) {
    this.set({
      stroke: flag ? '#99ff99' : '#444',
      strokeWidth: flag ? 3 : 2,
    })
  },

  isTranscriptNode: function() {
    return (this instanceof fabric.HumanNode) || (this instanceof fabric.BotNode)
  },

  //-EVENTS-------------------------------------------------------------------
  onAdded: function() {
    this.label = new fabric.NatureLabel({text: ''})
    this.canvas.add(this.label)
    this.updateNatureText()
  },

  onRemoved: function() {
    // console.log(this.type + '::onRemoved()')
    if(this.inPort) this.inPort.remove()
    if(this.outPort) this.outPort.remove()
    if(this.passPort) this.passPort.remove()
    if(this.failPort) this.failPort.remove()
    if(this.removeControl) this.removeControl.remove()
    if(this.label) this.label.remove()
  },

  onMoving: function(o) { //when moved by mouse
    this.updateAttached()
  },

  onSelected: function(o) {
    this.removeControl = new fabric.NodeRemoveControl({node: this})
    this.canvas.add(this.removeControl)
    this.updateAttached()
  },

  onDeselected: function(o) {
    setTimeout(() => {
      if(this.removeControl) {
        this.removeControl.remove()
        this.removeControl = null
      }
    }, 100)
  },

  //-INTERNAL-------------------------------------------------------------------
  setCoords: function(...args) { //when position set programatically, also called on init
    this.callSuper('setCoords', ...args);
    this.updateAttached()
  },

  updateAttached: function(){
    if(this.inPort) this.inPort.setaPos(this.getaLeftPoint())
    if(this.outPort) this.outPort.setaPos(this.getaRightPoint())
    if(this.passPort) this.passPort.setaPos(this.getaRightHighPoint())
    if(this.failPort) this.failPort.setaPos(this.getaRightLowPoint())

    if(this.removeControl) this.removeControl.setaPos(this.getaTopRightPoint())
    if(this.label) this.label.setaPos(this.getaCenterPoint())
  },

  updateNatureText: function() {
    this.label.set({text: this.getLabel()})
  },

})
setupProto()


//-----------------------------------------------------------------------------
fabric.StartNode = fabric.util.createClass(fabric.Node, {
  type: 'StartNode',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      
    }, options))

    this.setGradient('fill', {
      type: 'linear',
      x1: -this.width / 2,
      y1: 0,
      x2: this.width / 2,
      y2: 0,
      colorStops: {
        0: '#212121FF',
        1: '#2121218C',
      }
    })
  },

  onAdded: function() {
    this.callSuper('onAdded')

    if(!this.outPort) {
      this.outPort = new fabric.OutPort({node: this})
      this.canvas.add(this.outPort)
    }

    this.setCoords()
  },

  getLabel: () => 'Start',

})


//-----------------------------------------------------------------------------
fabric.EndNode = fabric.util.createClass(fabric.Node, {
  type: 'EndNode',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      nature: {
        message: '',
      },
    }, options))

    this.setGradient('fill', {
      type: 'linear',
      x1: -this.width / 2,
      y1: 0,
      x2: this.width / 2,
      y2: 0,
      colorStops: {
        0: '#212121FF',
        1: '#2121218C',
      }
    })
  },

  onAdded: function() {
    this.callSuper('onAdded')

    if(!this.inPort) {
      this.inPort = new fabric.InPort({node: this})
      this.canvas.add(this.inPort)
    }

    this.setCoords()
  },

  getLabel: () => 'End',

})
fabric.EndNode.prototype.isNatureEditable = true

//-----------------------------------------------------------------------------
fabric.HumanNode = fabric.util.createClass(fabric.Node, {
  type: 'HumanNode',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      nature: {
        patterns: [],
        bearingFree: false,
      },
    }, options))

    this.setGradient('fill', {
      type: 'linear',
      x1: -this.width / 2,
      y1: 0,
      x2: this.width / 2,
      y2: 0,
      colorStops: {
        0: '#2196F3FF',
        1: '#2196F38C',
      },
    })

  },

  getLabel: function() {
    var patterns = (this.nature && this.nature.patterns) || []
    return patterns.join('\n')
  },

  onAdded: function() {
    this.callSuper('onAdded')

    if(!this.inPort) {
      this.inPort = new fabric.InPort({node: this})
      this.canvas.add(this.inPort)
    }
    if(!this.outPort) {
      this.outPort = new fabric.OutPort({node: this})
      this.canvas.add(this.outPort)
    }
    this.setCoords()

    this.updateBearingFreeStyle()
  },

  setNature: function(nature) {
    this.callSuper('setNature', nature)
    this.updateBearingFreeStyle()
  },

  updateBearingFreeStyle: function() {
    // console.log('this.nature', this.nature, this.nature.bearingFree ? '#fff' : this.defaultOptions().stroke)
    this.set({stroke: this.nature.bearingFree ? '#fff' : this.defaultOptions().stroke, dirty:true})
  },

})
fabric.HumanNode.prototype.isNatureEditable = true


//-----------------------------------------------------------------------------
fabric.BotNode = fabric.util.createClass(fabric.Node, {
  type: 'BotNode',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      nature: {
        patterns: [],
      },
    }, options))

    this.setGradient('fill', {
      type: 'linear',
      x1: -this.width / 2,
      y1: 0,
      x2: this.width / 2,
      y2: 0,
      colorStops: {
        0: '#F44336FF',
        1: '#F443368C',
      },
    })
  },

  getLabel: function() {
    var patterns = (this.nature && this.nature.patterns) || []
    return patterns.join('\n')
  },

  onAdded: function() {
    this.callSuper('onAdded')

    if(!this.inPort) {
      this.inPort = new fabric.InPort({node: this})
      this.canvas.add(this.inPort)
    }
    if(!this.outPort) {
      this.outPort = new fabric.OutPort({node: this})
      this.canvas.add(this.outPort)
    }

    this.setCoords()
  },

})
fabric.BotNode.prototype.isNatureEditable = true


//-----------------------------------------------------------------------------
fabric.ConditionNode = fabric.util.createClass(fabric.Node, {
  type: 'ConditionNode',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      nature: {
        name: '', //'[K]'
        operation: '==',
        value: '', // '1'
      },
    }, options))

    this.setGradient('fill', {
      type: 'linear',
      x1: -this.width / 2,
      y1: 0,
      x2: this.width / 2,
      y2: 0,
      colorStops: {
        0: '#4CAF50FF',
        1: '#4CAF508C',
      },
    })
  },

  getLabel: function() {
    var n = this.nature
    return n ? (n.name + ' ' + n.operation + ' ' + n.value) : ''
  },

  onAdded: function() {
    this.callSuper('onAdded')

    if(!this.inPort) {
      this.inPort = new fabric.InPort({node: this})
      this.canvas.add(this.inPort)
    }
    if(!this.passPort) {
      this.passPort = new fabric.PassPort({node: this})
      this.canvas.add(this.passPort)
    }
    if(!this.failPort) {
      this.failPort = new fabric.FailPort({node: this})
      this.canvas.add(this.failPort)
    }
    this.setCoords()
  },

})
fabric.ConditionNode.prototype.isNatureEditable = true


//-----------------------------------------------------------------------------
fabric.ParameterNode = fabric.util.createClass(fabric.Node, {
  type: 'ParameterNode',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      nature: {
        name: '', //'[K]'
        operation: '+',
        value: '', //'1'
      },
    }, options))

    this.setGradient('fill', {
      type: 'linear',
      x1: -this.width / 2,
      y1: 0,
      x2: this.width / 2,
      y2: 0,
      colorStops: {
        0: '#673AB7FF',
        1: '#673AB78C',
      },
    })
  },

  getLabel: function() {
    var n = this.nature
    return n ? (n.name + ' ' + n.operation + ' ' + n.value) : ''
  },

  onAdded: function() {
    this.callSuper('onAdded')

    if(!this.inPort) {
      this.inPort = new fabric.InPort({node: this})
      this.canvas.add(this.inPort)
    }
    if(!this.outPort) {
      this.outPort = new fabric.OutPort({node: this})
      this.canvas.add(this.outPort)
    }
    this.setCoords()
  },

})
fabric.ParameterNode.prototype.isNatureEditable = true