var setupProto = () => {
  var proto = fabric.HookHeadArrow.prototype
  proto.defaultOptions = () => ({

  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = false
  proto.shouldPersist = false
  proto.isNatureEditable = false
}

fabric.HookHeadArrow = fabric.util.createClass(fabric.Triangle, {
  type: 'HookHeadArrow',
  baseType: 'HookHeadArrow',
  
  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },

  onAdded: function() {
    this.bringToFront()
  },
})
setupProto()