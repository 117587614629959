var setupProto = () => {
  var proto = fabric.NatureLabel.prototype
  proto.defaultOptions = () => ({
    breakWords: true,
    editable: false,

    width: 100-(15*2),
    height: 80-(10*2),
    
    fontSize: 14,
    lineHeight: 0.9,
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    // stroke: '#444',
    // strokeWidth: 2,
    textAlign: 'center',

    // backgroundColor: 'white',
    // textBackgroundColor: 'rgba(255,255,255,0.2)',
    // textBackgroundColor: 'rgba(0,0,0,0.2)',
    shadow: { //text shadow
      // color: 'rgba(0,0,0,0.3)',
      color: 'rgba(255,255,255,0.3)',
      offsetX: 0,
      offsetY: 0,
      blur: 4,
    },
  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = false
  proto.shouldPersist = false
  proto.isNatureEditable = false
}

fabric.NatureLabel = fabric.util.createClass(fabric.Textbox, {
  type: 'NatureLabel',
  baseType: 'NatureLabel',
  
  //EVENT event:changed
  initialize: function(options) {
    options = this.preInit(options)
    var text = options.text
    delete options.text //we add this as the first arg to super.initialize()
    this.callSuper('initialize', text, options)

    // maybe this event is just for direct user editing of the text
    // this.on('changed', (o) => {console.log('textbox onchanged', o)})
  },

  _splitTextIntoLines: function(ctx) {
    var lines = this.callSuper('_splitTextIntoLines', ctx)
    //uses fabric.Textbox.prototype._wrapLine
    //text is from this.text
    //numLines = this._textLines.length
    if(lines.length > 3)
      return lines.splice(0,3)
    return lines
  }
})
setupProto()

//override to break mid-word
fabric.Textbox.prototype._wrapLine = function(ctx, text, lineIndex) {
  var lineWidth        = 0,
      lines            = [],
      line             = '',
      words            = text.split(' '),
      word             = '',
      letter           = '',
      offset           = 0,
      infix            = ' ',
      wordWidth        = 0,
      infixWidth       = 0,
      letterWidth      = 0,
      largestWordWidth = 0;

  for (var i = 0; i < words.length; i++) {
      word = words[i];
      wordWidth = this._measureText(ctx, word, lineIndex, offset);
      lineWidth += infixWidth;

      // Break Words if wordWidth is greater than textbox width
      if (this.breakWords && wordWidth > this.width) {
          line += infix;
          var wordLetters = word.split('');
          while (wordLetters.length) {
              letterWidth = this._getWidthOfChar(ctx, wordLetters[0], lineIndex, offset);
              if (lineWidth + letterWidth > this.width) {
                  lines.push(line);
                  line = '';
                  lineWidth = 0;
              }
              line += wordLetters.shift();
              offset++;
              lineWidth += letterWidth;
          }
          word = '';
      } else {
          lineWidth += wordWidth;
      }

      if (lineWidth >= this.width && line !== '') {
          lines.push(line);
          line = '';
          lineWidth = wordWidth;
      }

      if (line !== '' || i === 1) {
          line += infix;
      }
      line += word;
      offset += word.length;
      infixWidth = this._measureText(ctx, infix, lineIndex, offset);
      offset++;

      // keep track of largest word
      if (wordWidth > largestWordWidth && !this.breakWords) {
          largestWordWidth = wordWidth;
      }
  }

  i && lines.push(line);

  if (largestWordWidth > this.dynamicMinWidth) {
    this.dynamicMinWidth = largestWordWidth;
  }

  return lines;
}