var setupProto = () => {
  var proto = fabric.NodeRemoveControl.prototype
  proto.defaultOptions = () => ({
    stroke: '#F00',
    fill: '#FF0000AA',
    radius: 8,
    hoverCursor: 'pointer',

    node: null,
  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = true
  proto.shouldPersist = false
  proto.isNatureEditable = false
}

fabric.NodeRemoveControl = fabric.util.createClass(fabric.Circle, {
  type: 'NodeRemoveControl',
  baseType: 'NodeRemoveControl',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },

  onMouseDown: function() {
    this.node.remove()
  },

})
setupProto()