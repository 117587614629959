import ops from '../../../util/Ops'

const oproto = fabric.Object.prototype

oproto.preInit = function(instanceOptions) {
  var baseOptions = {
    id: ops.shortId(),

    originX: 'center',
    originY: 'center',

    selectable: this.isSelectable,
    evented: this.isEventable,
  }
  var options = Object.assign(baseOptions, this.defaultOptions(), instanceOptions)
  this.initEventListeners()
  return options
}

oproto.initEventListeners = function() {
  if(this.onAdded) this.on('added', this.onAdded)
  if(this.onRemoved) this.on('removed', this.onRemoved)
  if(this.onMoving) this.on('moving', this.onMoving)
  if(this.onSelected) this.on('selected', this.onSelected)
  if(this.onDeselected) this.on('deselected', this.onDeselected)
  if(this.onMouseDown) this.on('mousedown', this.onMouseDown)
}

oproto.scaledWidth = function() { return this.width * this.scaleX }
oproto.scaledHeight = function() { return this.height * this.scaleY }

oproto.setPos = function(point) {
  this.set({
    left: point.x,
    top: point.y,
  })
  this.setCoords()
}

oproto.getLeftPoint = function() {
  var cp = this.getCenterPoint()
  return {
    x: cp.x - this.scaledWidth()/2,
    y: cp.y,
  }
}

oproto.getRightPoint = function() {
  var cp = this.getCenterPoint()
  return {
    x: cp.x + this.scaledWidth()/2,
    y: cp.y,
  }
}

oproto.getTopPoint = function() {
  var cp = this.getCenterPoint()
  return {
    x: cp.x,
    y: cp.y - this.scaledHeight()/2,
  }
}

oproto.getBottomPoint = function() {
  var cp = this.getCenterPoint()
  return {
    x: cp.x,
    y: cp.y + this.scaledHeight()/2,
  }
}

oproto.getRightHighPoint = function() {
  var cp = this.getCenterPoint()
  return {
    x: cp.x + this.scaledWidth()/2,
    y: cp.y - this.scaledHeight()/4,
  }
}

oproto.getRightLowPoint = function() {
  var cp = this.getCenterPoint()
  return {
    x: cp.x + this.scaledWidth()/2,
    y: cp.y + this.scaledHeight()/4,
  }
}

oproto.getTopRightPoint = function() {
  var cp = this.getCenterPoint()
  return {
    x: cp.x + this.scaledWidth()/2,
    y: cp.y - this.scaledHeight()/2,
  }
}


oproto.setaPos = function(point) {
  if(this.group) {
     var gcp = this.group.getCenterPoint()
     point.x -= gcp.x
     point.y -= gcp.y
  }
  this.set({
    left: point.x,
    top: point.y,
  })
  this.setCoords()
}

oproto.setaPosLine = function(point0, point1) {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    point0.x -= gcp.x
    point0.y -= gcp.y
    point1.x -= gcp.x
    point1.y -= gcp.y
  }
  this.set({
    x1: point0.x,
    y1: point0.y,
    x2: point1.x,
    y2: point1.y,
  })
  this.setCoords()
}

oproto.getaCenterPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(gcp.x+cp.x, gcp.y+cp.y)
  }
  return this.getCenterPoint()
}

oproto.getaLeftPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(
      gcp.x+cp.x - this.scaledWidth()/2,
      gcp.y+cp.y
    )
  }
  return this.getLeftPoint()
}

oproto.getaRightPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(
      gcp.x+cp.x + this.scaledWidth()/2,
      gcp.y+cp.y
    )
  }
  return this.getRightPoint()
}

oproto.getaTopPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(
      gcp.x+cp.x,
      gcp.y+cp.y - this.scaledHeight()/2
    )
  }
  return this.getTopPoint()
}

oproto.getaBottomPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(
      gcp.x+cp.x,
      gcp.y+cp.y + this.scaledHeight()/2
    )
  }
  return this.getBottomPoint()
}

oproto.getaRightHighPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(
      gcp.x+cp.x + this.scaledWidth()/2,
      gcp.y+cp.y - this.scaledHeight()/4
    )
  }
  return this.getRightHighPoint()
}

oproto.getaRightLowPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(
      gcp.x+cp.x + this.scaledWidth()/2,
      gcp.y+cp.y + this.scaledHeight()/4
    )
  }
  return this.getRightLowPoint()
}

oproto.getaTopRightPoint = function() {
  if(this.group) {
    var gcp = this.group.getCenterPoint()
    var cp = this.getCenterPoint()
    return new fabric.Point(
      gcp.x+cp.x + this.scaledWidth()/2,
      gcp.y+cp.y - this.scaledHeight()/2
    )
  }
  return this.getTopRightPoint()
}


// export function injectHelperFunctions(className, isBaseClass=false) {
//   var klass = fabric[className]
//   var proto = klass.prototype

//   // klass.fromObject = function(object, callback, forceAsync) {
//   //   return fabric.Object._fromObject(className, object, callback, forceAsync);
//   // }
//   // fabric.Link.fromObject = function(object, callback, forceAsync) {
//   //   return fabric.Object._fromObject('Link', object, callback, forceAsync)
//   // }
// }
