import React from 'react'
import { Link } from 'react-router-dom'

const ROLE_ADMIN = 'admin'

class Auth extends React.Component {
  componentDidMount() {
    
  }

  render () {
    var user = this.props.user && this.props.user.toJS()

    var {pathname, hash, search} = this.props.location
    var currentReferrer = pathname + hash + search
    var loginUrl = '/auth/facebook?referrer='+(this.props.authReferrer||currentReferrer)

    return (
      <div className='auth'>
        {user && user.role == ROLE_ADMIN && (
          <Link to='/admin'>
            <button className='btn btn-light btn-xs'>ADMIN</button>
          </Link>
        )}
        {user && (
          <Link to='/profile'>
            <button className='btn btn-light btn-xs'>Profile</button>
          </Link>
        )}
        {user && <span className='ispan-center'>{user.name}</span>}
        {user && (
          <button className='btn btn-light btn-xs' onClick={this.props.actions.logout}>
            <span className='fa fa-times'></span>
          </button>
        )}
        

        {!user && (
          <a href={loginUrl} className='btn btn-primary btn-sm'>
            <span className='fa fa-facebook'></span> Login With Facebook
          </a>
        )}
      </div>
    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    user: state.get('user'),
    authReferrer: state.get('authReferrer'),
  }),
  (dispatch) => ({ //map dispatch to props
    actions: actions,
  })
)(Auth)