'use strict';

export default {
    Circle: 'circle',
    Line: 'line',
    Pencil: 'pencil',
    Rectangle: 'rectangle',
    Select: 'select',
    Pan: 'pan',
    Text: 'text',
}