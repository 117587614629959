var setupProto = () => {
  var proto = fabric.Link.prototype
  proto.defaultOptions = () => ({
    fill: false,
    stroke: '#212121',
    strokeWidth: 2,

    hasControls: false,
    hasBorders: false,
    // lockMovementX: true,
    // lockMovementY: true,
    // hoverCursor: 'default',

    hookCoords: [], //temporary initial coords, default requires 2 coords
    hooks: [],
    lines: [],
  })

  proto.isDependant = true //for undo/redo
  proto.isSelectable = false
  proto.isEventable = false
  proto.shouldPersist = true
  proto.isNatureEditable = false
}


fabric.Link = fabric.util.createClass(fabric.Path, {
  type: 'Link',
  baseType: 'Link',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', '', options)
  },


  //-SERIALIZATION----------------------------------------------------------------
  toObject: function() {
    // console.log('Link::toObject:', this.hooks)
    // var to = fabric.util.object.extend(this.callSuper('toObject'), {
    //   id: this.id,
    //   baseType: this.baseType,
    //   hooksIds: this.hooks.map(hook => hook.id),
    // })
    // // console.log('to', to)
    // return to

    return {
      id: this.id,
      type: this.type,
      baseType: this.baseType,

      left: this.left,
      top: this.top,
      
      hooksIds: this.hooks.map(hook => hook.id),
    }
  },

  revive: function(allObjects) {
    for(let hookId of this.hooksIds) {
      var hook = allObjects.find((o) => o.id == hookId)
      this.hooks.push(hook)
    }
    delete this.hooksIds
  },


  //-FUNCTIONS--------------------------------------------------------------------
  headHook: function() { return this.hooks[this.hooks.length-1] },
  tailHook: function() { return this.hooks[0] },

  splitLine: function(line) {
    var lineIndex = this.lines.indexOf(line)

    var hook = new fabric.NeutralHook({
      left: line.getaCenterPoint().x,
      top: line.getaCenterPoint().y,
      link: this,
    })
    this.hooks.splice(lineIndex+1, 0, hook)
    fabric.Link.latestNeutralHook = hook //set global reference to be referenced in the canvas
    this.canvas.add(hook)
    this.createLines()
  },

  removeHook: function(hook) {
    if(this.isRemoving)
      return
    
    var hookIndex = this.hooks.indexOf(hook)
    this.hooks.splice(hookIndex, 1)
    this.createLines()
  },

  createLines: function () {
    while(this.lines.length > 0) {
      let line = this.lines.pop()
      // console.log('remove line', line)
      line.remove()
    }

    var hooks = this.hooks
    // var pathArg = ' M 0 0 '
    // for(let i=0; i<hooks.length; i++)
    //     pathArg += ' L ' + hooks[i].left + ' ' + hooks[i].top + ' '
    // // pathArg += ' z ' //add 'z' to close 

    for(let i=0; i<hooks.length-1; i++) { //shortened loop
      let line = new fabric.LinkLine({link: this})
      this.lines.push(line)
      this.canvas.add(line)
      line.sendToBack()
    }
    this.updateLines()
  },

  updateLines: function () {
    var lines = this.lines
    var hooks = this.hooks
    for(let i=0; i<lines.length; i++) {
      let p0 = hooks[i].getaCenterPoint()
      let p1 = hooks[i+1].getaCenterPoint()
      lines[i].setaPosLine(p0, p1)
    }
    this.updateHeadHook()
  },

  getPrevHookPos: function(hook) {
    var hookIndex = this.hooks.indexOf(hook)
    var prevHook = this.hooks[Math.max(hookIndex-1, 0)]
    return prevHook.getaCenterPoint()
  },

  //-EVENTS--------------------------------------------------------------------
  onAdded: function() {
    while(this.hooks.length + this.hookCoords.length < 2)
      this.hookCoords.push({x:0, y:0})

    var coords = this.hookCoords
    for(let i=0; i<coords.length; i++) {
      var coord = coords[i]
      //segment is like ["L", 100, 100]
      var hookKlass = (i==0&&fabric.TailHook)||(i==coords.length-1&&fabric.HeadHook)||fabric.NeutralHook
      var hook = new hookKlass({
        left: coord.x,
        top: coord.y,
        link: this,
      })
      this.hooks.push(hook)
      this.canvas.add(hook)
    }
    this.createLines()
    this.sendToBack()
  },

  onRemoved: function() {
    this.isRemoving = true
    for(let hook of this.hooks) {
      hook.remove()
    }
    for(let line of this.lines)
      line.remove()
    this.isRemoving = false
  },


  //-INTERNAL--------------------------------------------------------------------
  setCoords: function(...args) { //when position set programatically
    var dims = this._parseDimensions()
    this.setWidth(dims.width)
    this.setHeight(dims.height)
    this.callSuper('setCoords', ...args)
    this.updateAttached()
  },

  updateAttached: function(){
    if(this.removeControl) this.removeControl.setaPos(this.getaCenterPoint())
    this.updateLines()
  },

  updateHeadHook: function() {
    var headHook = this.headHook()
    // console.log('headHook', headHook)
    if(headHook && headHook.updateArrow) headHook.updateArrow()
  },

})
setupProto()

//-----------------------------------------------------------------------------