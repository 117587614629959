import Immutable from 'immutable'
import * as Store from './store'

import Voice from '../util/Voice'
import config from '../config'

const isLocalhost = location.href.indexOf('localhost') != -1

const initialState = Immutable.fromJS({
  isFetchingUser: false,
  user: null,
  authReferrer: null,
  renderVoiceControls: Voice.isSupported() && !config.isMobile, //mobile can use their own keyboard voice

  prChatKey: null,

  home: {
    callerInput: '',
    chatCountLimit: config.isMobile ? 1 : false,
  },

  sketch: {
    botKey: null,
    socket: null,
    job: null,
    cellData: null,
    kiwiContext: null,
    chatKey: null,

    bot: {},
  },

  chats: Immutable.Map({
    //<chatKey>:<initialChatState>
    //<chatKey2>:<initialChatState2>
    //<chatKey3>:<initialChatState3>
  }),
})

export const initialChatState = (botKey, customOpts={}) => {
  return Immutable.fromJS({
    botKey: botKey,
    conversationId: null,
    socket: null,

    messages: [],
    kiwiContext: null,
    endContext: null,
    endMessage: null,
    bot: {},
    
    showFeedback: false,
    persist: true,

    isExpanded: true,
    hasBotVoice: true,
    job: null,
    message: '', //isLocalhost ? 'おまたせ' : '',
  }).merge(customOpts)
}

export default Store.createDefaultReducer(initialState)