import React from 'react'
import { Link } from 'react-router-dom'

var ROLE_ADMIN = 'admin'

class Nav extends React.Component {

  render() {
    var user = this.props.user && this.props.user.toJS()

    var loginUrl = '/auth/facebook'
    if(this.props.authReferrer)
      loginUrl += '?referrer='+this.props.authReferrer

    return (
      <nav className='navbar navbar-light bg-light navbar-expand-sm justify-content-between'>
        <Link className='navbar-brand' to='/'>
          <img src='/site/assets/sakura_face.jpg' width='30' height='30' className='d-inline-block align-top mr-1'/>
          Chatty Coach
        </Link>
        <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <Link to='/chat' className='nav-link'>Chat</Link>
            </li>
          </ul>

          <ul className='navbar-nav justify-content-end'>
          
            {user && user.role == ROLE_ADMIN && (
              <li className='nav-item'>
                <Link to='/admin' className='nav-link'>Admin</Link>
              </li>
            )}
            {user && (
              <li className='nav-item'>
                <Link to='/profile' className='nav-link'>Profile</Link>
              </li>
            )}
            {user && (
              <li className='nav-item'>
                <button className='nav-link btn btn-link no-border' 
                  onClick={this.props.actions.logout}>
                  Logout
                </button>
              </li>
            )}

            {!user && (
              <Link to={loginUrl} className='btn btn-primary btn-sm'>
                <span className='fa fa-facebook'></span> Login With Facebook
              </Link>
            )}
          </ul>
        </div>
      </nav>
    )
  }

}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    user: state.get('user'),
    authReferrer: state.get('authReferrer'),
  }),
  (dispatch) => ({ //map dispatch to props
    actions: actions,
  })
)(Nav)
