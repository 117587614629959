import React from 'react'
import axios from 'axios'

import Nav from './Nav'


class AdminPage extends React.Component {

  state = {
    activeTab: 0,
    activeUser: null,

    users: null,
    bots: null,

    sessionCount: '?',
    conversationCount: '?',
  }

  componentDidMount() {
    this.fetchUsers()
    this.fetchBots()
    this.fetchConversationCount()
    this.fetchSessionCount()
  }

  fetchUsers = () => {
    axios.get('/users')
      .then((res) => {
        // console.log('res.data', res.data)
        var users = res.data.users
        this.setState({
          users: users,
          activeUser: users.length > 0 ? users[0] : null,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  onClearUsers = () => {
    if(!confirm('Are you sure?'))
      return

    axios.delete('/users')
      .then((res) => {
        // console.log('res.data', res.data)
        this.fetchUsers()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  fetchConversationCount = () => {
    axios.get('/conversation_count')
      .then((res) => {
        // console.log('res.data', res.data)
        this.setState({
          conversationCount: res.data.conversationCount,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }


  onClearConversations = () => {
    if(!confirm('Are you sure?'))
      return

    axios.delete('/conversations')
      .then((res) => {
        // console.log('res.data', res.data)
        this.fetchConversationCount()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  fetchBots = () => {
    axios.get('/bots')
      .then((res) => {
        // console.log('res.data', res.data)
        var bots = res.data.bots
        this.setState({
          bots: bots,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  onClearBots = () => {
    if(!confirm('Are you sure?'))
      return

    axios.delete('/bots')
      .then((res) => {
        // console.log('res.data', res.data)
        this.fetchBots()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  fetchSessionCount = () => {
    axios.get('/session_count')
      .then((res) => {
        // console.log('res.data', res.data)
        this.setState({
          sessionCount: res.data.sessionCount,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    var user = this.props.user && this.props.user.toJS()
    var {activeTab} = this.state

    return (
      <div className='admin-page'>

        <Nav/>

        <div className='admin-main'>

          <div className='sidebar'>
            <ul className='sidebar-nav'>
              <li className={(activeTab==0?'active':'')} onClick={() => this.setState({activeTab:0})}><a>Dashboard</a></li>
              <li className={(activeTab==1?'active':'')} onClick={() => this.setState({activeTab:1})}><a>Users</a></li>
            </ul>
          </div>

          <div className='viewport'>
            {activeTab==0 && this.renderDashboardView()}
            {activeTab==1 && this.renderUsersView()}
          </div>

        </div>

      </div>
    )
  }
  renderDashboardView = () => {
    return (
      <div className='dashboard-view container-fluid'>
        <div className='row'>

          <div className='col-sm-6'>
            <div className='card'>
              <div className='card-header'><strong>Sessions</strong></div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-sm-12'>Session Count: {this.state.sessionCount}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-sm-6'>
            <div className='card'>
              <div className='card-header'><strong>Services</strong></div>
              <div className='card-body'>
                <div className='row hgutter-4 col'>
                  <a href='https://developers.facebook.com/apps/287392848457941/dashboard' className='btn btn-primary'>Facebook App Dashboard</a>
                  <a href='https://analytics.google.com/analytics/web/#realtime/rt-overview/a4915651w173012214p172448949/' className='btn btn-primary'>Google Analytics</a>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='row'>

          <div className='col-sm-6'>
            <div className='card'>
              <div className='card-header'><strong>Database</strong></div>
              <div className='card-body'>
                <div className='db-row'>
                  <div>Users: {this.state.users ? this.state.users.length : '?'}</div>
                  <div className='btn btn-danger' onClick={this.onClearUsers}>DELETE ALL</div>
                </div>
                <div className='hrule'/>
                <div className='db-row'>
                  <div>Bots: {this.state.bots ? this.state.bots.length : '?'}</div>
                  <div className='btn btn-danger' onClick={this.onClearBots}>DELETE ALL</div>
                </div>
                <div className='hrule'/>
                <div className='db-row'>
                  <div>Conversations: {this.state.conversationCount}</div>
                  <div className='btn btn-danger' onClick={this.onClearConversations}>DELETE ALL</div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    )
  }
  renderUsersView = () => {
    const {users, activeUser, bots} = this.state

    if(!users) return null

    var userBots = []
    if(activeUser && bots) {
      userBots = bots.filter(bot => bot.userId == activeUser.id)
    }

    return (
      <div className='users-view'>

        <div className='view-header'>Users</div>

        <div className='view-body'>

          <ul className='users-list'>
            {users.map(user => {
              return (
                <li 
                  className={(activeUser==user?'active':'')}
                  onClick={() => this.setState({activeUser: user})}
                  >
                  {user.facebookName}
                </li>
              )
            })}
          </ul>

          {activeUser && 
            <div className='user-details'>
              <div className='user-props'>
                <div><label>ID:</label><span>{activeUser.id}</span></div>
                <div><label>Name:</label><span>{activeUser.facebookName}</span></div>
                <div><label>Role:</label><span>{activeUser.role}</span></div>
                <div><label>Email:</label><span>{activeUser.facebookEmail}</span></div>
              </div>

              <div className='user-bots'>
                <div className='card-header'><strong>Bots</strong></div>
                <div className='card-body'>
                  <ul className=''>
                    {userBots.map(bot => {
                      return (
                        <li className={''}>
                          {bot.botKey}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>

      </div>
    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    user: state.get('user'),
  }),
  (dispatch) => ({ //map dispatch to props
     actions: actions,
  })
)(AdminPage)