var setupProto = () => {
  var proto = fabric.Hook.prototype
  proto.defaultOptions = () => ({
    strokeWidth: 1,
    radius: 8,
    fill: '',
    stroke: 'red',

    hasControls: false,
    hasBorders: true,
    borderScaleFactor: 2,

    link: null,
    port: null,
  })

  proto.isDependant = false //for undo/redo
  proto.isSelectable = true
  proto.isEventable = true
  proto.shouldPersist = true
  proto.isNatureEditable = false
}

fabric.Hook = fabric.util.createClass(fabric.Circle, {
  type: 'Hook',
  baseType: 'Hook',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },

  //-SERIALIZATION----------------------------------------------------------------
  toObject: function() {
    // return fabric.util.object.extend(this.callSuper('toObject'), {
    //   id: this.id,
    //   baseType: this.baseType,
    //   linkId: this.link ? this.link.id : null,
    //   portId: this.port ? this.port.id : null,
    // })
    return {
      id: this.id,
      type: this.type,
      baseType: this.baseType,

      left: this.left,
      top: this.top,

      linkId: this.link ? this.link.id : null,
      portId: this.port ? this.port.id : null,
    }
  },

  revive: function(allObjects) {
    this.link = allObjects.find((o) => o.id == this.linkId)
    // console.log('hook revive:: ', this.linkId, ' --> ', this.link, allObjects.map(o => o.id))
    delete this.linkId

    this.port = allObjects.find((o) => o.id == this.portId)
    delete this.portId
    this.updateAttached()
  },

  //-EVENTS--------------------------------------------------------------------
  onAdded: function() {
    //Need this function definition to allow super calls from subclasses
    this.bringToFront()
  },

  onRemoved: function() {
    if(this.port)
      this.port.detachHook(this)
    if(this.removeControl) this.removeControl.remove()
  },

  onMoving: function(o) { //when moved by mouse
    // console.log('Hook::onMoving(): ', o)
    var hook = this
    var canvas = hook.canvas
    var pointer = canvas.getPointer(o.e)

    var nearPort = null
    canvas.forEachObject((o, i) => {
      if(canAttach(hook, o)) {
        var port = o

        var dis = hook.getaCenterPoint().distanceFrom(port.getaCenterPoint())
        if(dis < 50) {
          nearPort = port
        }
      }
    })

    if(nearPort) {
      nearPort.attachHook(hook)

      //prevent hook from moving with mouse, also causes line to update
      hook.setaPos(nearPort.getaHookPoint()) 
    }
    else {
      if(hook.port)
        hook.port.detachHook(hook)

      //update link
      hook.updateAttached()
    }
  },

  onSelected: function(e) {
    // console.log('selected', this)
    this.removeControl = new fabric.HookRemoveControl({hook: this})
    this.canvas.add(this.removeControl)
    this.updateAttached()
  },

  onDeselected: function(e) {
    setTimeout(() => {
      if(this.removeControl) {
        this.removeControl.remove()
        this.removeControl = null
      }
    }, 100)
  },
  
  //-INTERNAL--------------------------------------------------------------------
  setCoords: function(...args) { //after position set programatically
    this.callSuper('setCoords', ...args)
    this.updateAttached()
  },

  updateAttached: function(){
    if(this.removeControl) this.removeControl.setaPos(this.getaTopRightPoint())
  },

})
setupProto()

function canAttach(hook, object) {
  if(!(object instanceof fabric.Port))
    return false

  if(hook instanceof fabric.NeutralHook)
    return false

  var port = object

  var isInPort = port instanceof fabric.InPort
  var isOutPort = port instanceof fabric.OutPort
              || port instanceof fabric.PassPort
              || port instanceof fabric.FailPort

  var isTailHook = hook instanceof fabric.TailHook
  var isHeadHook = hook instanceof fabric.HeadHook

  if(isInPort && !isHeadHook)
    return false

  if(isOutPort && !isTailHook)
    return false

  var tailNode = isTailHook ? port.node : (hook.link.tailHook().port && hook.link.tailHook().port.node)
  var headNode = isHeadHook ? port.node : (hook.link.headHook().port && hook.link.headHook().port.node)

  //cant link to same node
  if(tailNode == headNode)
    return false

  //can link human->human and bot->bot
  if((tailNode && headNode) && 
    (tailNode instanceof fabric.HumanNode && headNode instanceof fabric.HumanNode)
    || (tailNode instanceof fabric.BotNode && headNode instanceof fabric.BotNode))
    return false

  return true
}

//-----------------------------------------------------------------------------
fabric.TailHook = fabric.util.createClass(fabric.Hook, {
  type: 'TailHook',
  baseType: 'Hook',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      fill: '#000000FF', //'#000000AA',
      stroke: '#000000FF',
    }, options))
  },

  onRemoved: function() {
    this.callSuper('onRemoved')
    this.link.remove()
  },

  updateAttached: function() {
    this.callSuper('updateAttached')
    if(this.link) //need this when uploading
      this.link.updateLines()
  },

})


//-----------------------------------------------------------------------------
fabric.NeutralHook = fabric.util.createClass(fabric.Hook, {
  type: 'NeutralHook',
  baseType: 'Hook',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      stroke: '#21212199',
      radius: 6,
    }, options))
  },

  onRemoved: function() {
    this.callSuper('onRemoved')
    this.link.removeHook(this)
  },

  updateAttached: function() {
    this.callSuper('updateAttached')
    if(this.link) //need this when uploading
      this.link.updateLines()
  },

})


//-----------------------------------------------------------------------------
fabric.HeadHook = fabric.util.createClass(fabric.Hook, {
  type: 'HeadHook',
  baseType: 'Hook',

  initialize: function(options) {
    this.callSuper('initialize', Object.assign({
      fill: '#00000000',
      stroke: '#00000000',
    }, options))
  },

  onAdded: function() {
    this.callSuper('onAdded')
    this.arrow = new fabric.HookHeadArrow({
      width: this.width,
      height: this.height,
      strokeWidth: this.strokeWidth,
      fill: '#FF0000FF', //'#FF0000AA',
      stroke: '#FF0000FF',
      shadow: this.shadow,
    })
    this.canvas.add(this.arrow)
    this.updateAttached()
  },

  onRemoved: function() {
    this.callSuper('onRemoved')
    this.link.remove()
    if(this.arrow) this.arrow.remove()
  },

  updateAttached: function() {
    this.callSuper('updateAttached')
    if(this.link) //need this when uploading
      this.link.updateLines()
  },

  updateArrow: function() {
    if(this.arrow) {
      var prevHookPos = this.link.getPrevHookPos(this)
      var curHookPos = this.getaCenterPoint()
      var angle = calcArrowAngle(prevHookPos.x, prevHookPos.y, curHookPos.x, curHookPos.y)
      // console.log(prevHookPos, curHookPos)
      this.arrow.setaPos(this.getaCenterPoint())
      this.arrow.set('angle', angle+90)
      this.arrow.setCoords()
    }
  },

})


function calcArrowAngle(x1, y1, x2, y2) {
  var angle = 0, x, y

  x = (x2 - x1)
  y = (y2 - y1)

  if (x === 0) {
    angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2
  } else if (y === 0) {
    angle = (x > 0) ? 0 : Math.PI
  } else {
    angle = (x < 0) ? Math.atan(y / x) + Math.PI : (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x)
  }

  return (angle * 180 / Math.PI)
}