/*eslint no-unused-vars: 0*/
'use strict';

import FabricCanvasTool from './fabrictool'
const fabric = require('fabric').fabric;

class Pan extends FabricCanvasTool {

    configureCanvas(props) {
        let canvas = this._canvas;
        canvas.isDrawingMode = false;
        canvas.selection = false;
        canvas.forEachObject((o) => o.selectable = o.evented = false);
        //Change the cursor to the move grabber
        canvas.defaultCursor = 'move';

        //reset state
        this.isDown = false;
    }

    doMouseDown(o) {
        let canvas = this._canvas;
        this.isDown = true;
        let pointer = canvas.getPointer(o.e);
        this.startX = pointer.x;
        this.startY = pointer.y;
    }

    doMouseMove(o) {
        if (!this.isDown) return;
        let canvas = this._canvas;
        let pointer = canvas.getPointer(o.e);

        var scaleX = canvas.viewportTransform[0]
        var dx = (pointer.x - this.startX) * scaleX
        var scaleY = canvas.viewportTransform[3]
        var dy = (pointer.y - this.startY) * scaleY

        canvas.relativePan({x: dx, y: dy})
        canvas.renderAll()
    }

    doMouseUp(o) {
        this.isDown = false;
    }

}

export default Pan;