import React from 'react'
import { Link } from 'react-router-dom'

import KiwiChat from './KiwiChat'

const APP_NAME = 'CHaTTy COaCH'

const SAMPLE_BOT_KEY = 'ByTPN4CuG'

const START_NOW_URL = '/chat'
const CONTACT_URL = 'https://www.akademia.co.jp/en/contact'
const PRIVACY_POLICY_URL = '/site/docs/privacy.html'

const SHOWCASE_IMAGE_URL_1 = '/site/assets/sample.png'
const SHOWCASE_IMAGE_URL_2 = '/site/assets/tensor_accuracy.png'
const SHOWCASE_IMAGE_URL_3 = '/site/assets/handheld.JPG'

class PRPage extends React.Component {

  componentWillMount() {
    let lang
    let browserLang = window.navigator.userLanguage || window.navigator.language
    if(browserLang.indexOf('ja') != -1 || browserLang.indexOf('jp') != -1)
      lang = 'jp'
    else
      lang = 'en'
    this.state = {lang}
  }

  componentDidMount() {
    // // jQuery for page scrolling feature - requires jQuery Easing plugin
    // $(document).on('click', 'a.page-scroll', function(event) {
    //   var $anchor = $(this)
    //   $('html, body').stop().animate({
    //     scrollTop: ($($anchor.attr('href')).offset().top - 50)
    //   }, 1250, 'easeInOutExpo')
    //   event.preventDefault()
    // })

    // // Highlight the top nav as scrolling occurs
    // $('body').scrollspy({
    //   target: '.navbar-fixed-top',
    //   offset: 100
    // })

    // // Closes the Responsive Menu on Menu Item Click
    // $('.navbar-collapse ul li a').click(function() {
    //   $('.navbar-toggle:visible').click()
    // })

    // // Offset for Main Navigation
    // $('#mainNav').affix({
    //   offset: {
    //     top: 50
    //   }
    // })
  }

  onStartChat = () => {
    if(this.props.prChatKey)
      return

    var chatKey = this.props.actions.startKiwiChat(SAMPLE_BOT_KEY, {
      showFeedback: false,
      isExpanded: true,
      persist: false,
    })
    this.props.actions.set('prChatKey', chatKey)
  }

  onCloseChat = () => {
    this.props.actions.set('prChatKey', null)
  }

  render() {
    return (
      <div id='page-top' className={'pr-page ' + this.state.lang}>
        
        <header className='masthead text-white text-center'>
          <div className='overlay'></div>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-9 mx-auto'>
                <h1 className=''>{APP_NAME}</h1>
                <h2 className='mb-5'>
                  <span lang='en'>Interactive Roleplay Exercise Robot for Japanese Learning.  Practice your Japanese language with your coach to succeed in a realistic communication!</span>
                  <span lang='jp'>日本語学習のための RolePlay型対話練習のロボット。日本語の対話練習をいつでもどこでも効果的にでき、対話スキルの評価もできる!</span>
                </h2>
              </div>
              <div className='col-md-10 col-lg-8 col-xl-7 mx-auto'>
                <form>
                  <div className='form-row'>
                    <div className='col-4'></div>
                    <div className='col-4'>
                      <Link to={START_NOW_URL} className='btn btn-block btn-lg btn-primary'>Start Now!</Link>
                    </div>
                    <div className='col-4'></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </header>

        <section className='features-icons bg-light text-center'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className='features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3'>
                  <div className='features-icons-icon d-flex'>
                    <i className='icon-screen-desktop m-auto text-primary'></i>
                  </div>
                  <h3>
                    <span lang='en'>Voice Support</span>
                    <span lang='jp'>音声での対話練習</span>
                  </h3>
                  <p className='lead mb-0'>
                    <span lang='en'>You can practice roleplay-type dialogue exercise by voice.</span>
                    <span lang='jp'>音声でRolePlay型対話練習ができる</span>
                  </p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3'>
                  <div className='features-icons-icon d-flex'>
                    <i className='icon-layers m-auto text-primary'></i>
                  </div>
                  <h3>
                    <span lang='en'>Grading Evaluation</span>
                    <span lang='jp'>対話スキル評価</span>
                  </h3>
                  <p className='lead mb-0'>
                    <span lang='en'>Automatic and customizable scoring for each roleplay</span>
                    <span lang='jp'>RolePlayの成績の評価を定量評価できる</span>
                  </p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='features-icons-item mx-auto mb-0 mb-lg-3'>
                  <div className='features-icons-icon d-flex'>
                    <i className='icon-check m-auto text-primary'></i>
                  </div>
                  <h3>
                    <span lang='en'>Create Custom Roleplay</span>
                    <span lang='jp'>ロループレイモデル作成</span>
                  </h3>
                  <p className='lead mb-0'>
                    <span lang='en'>You can easily create and customize RolePlay dialog model of interactive practice.</span>
                    <span lang='jp'>対話練習のRolePlay対話モデルを、簡単に作成、カスタマイズできる。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='showcase'>
          <div className='container-fluid p-0'>
            <div className='row no-gutters'>

              <div className='col-lg-6 order-lg-2 text-white showcase-img' style={{backgroundImage: 'url("' + SHOWCASE_IMAGE_URL_1 + '")'}}></div>
              <div className='col-lg-6 order-lg-1 my-auto showcase-text'>
                <h2>
                  <span lang='en'>GUI Conversation Designer</span>
                  <span lang='jp'>対話管理をグラフィカルなインタフェースで作成</span>
                </h2>
                  <ul lang='en' style={{listStyleType: 'disc'}}>
                    <li className='lead mb-0'>intention understanding by Ai-chatbot</li>
                    <li className='lead mb-0'>Scenario interaction management(Quantitative evaluation of roleplay grades)</li>
                    <li className='lead mb-0'>Conversation design with graphical user interface</li>
                  </ul>
                  <ul lang='jp' style={{listStyleType: 'disc'}}>
                    <li className='lead mb-0'>音声認識+AI-ChatBotによる意図理解</li>
                    <li className='lead mb-0'>シナリオ対話管理と対話成績の定量評価</li>
                    <li className='lead mb-0'>GUIで対話モデル作成</li>
                  </ul>
              </div>
            </div>
            <div className='row no-gutters'>
              <div className='col-lg-6 text-white showcase-img' style={{backgroundImage: 'url("' + SHOWCASE_IMAGE_URL_2 + '")'}}></div>
              <div className='col-lg-6 my-auto showcase-text'>
                <h2>Tensorflow</h2>
                <p lang='en' className='lead mb-0'>
                  Application of Tensorflow&#39;s Neural Network learning as
                  recognition process of dialog scenario
                  Recognize the scenario according to the condition of
                  Contex for dialogue
                </p>
                <p lang='jp' className='lead mb-0'>
                  対話のシナリオの認識処理として、Tensorflowによる人口知能
                  （Neural Network）学習を適用
                  Contexの条件に合わせて、シナリオを認識させる
                </p>
              </div>
            </div>
            <div className='row no-gutters'>
              <div className='col-lg-6 order-lg-2 text-white showcase-img' style={{backgroundImage: 'url("' + SHOWCASE_IMAGE_URL_3 + '")'}}></div>
              <div className='col-lg-6 order-lg-1 my-auto showcase-text'>
                <h2　lang='en'>Communication Skill Evaluation</h2>
                <h2 lang='jp'>対話スキル評価</h2>
                <p lang='en' className='lead mb-0'>
                  Development of effective communication skill learning and
                  evaluation method of communication skill by using dialogue
                  with a computer
                  By practicing dialogue with a computer bot in RolePlay
                  style, practical communication skills are effectively
                  improved. At the same time, you can evaluate the
                  communication skills by quantitatively evaluating the
                  dialogue practice in RolePlay.
                </p>
                <p lang='jp' className='lead mb-0'>
                  コンピュータによるRolePlay型対話練習と対話スキルの評価方式の
                  開発
                  RolePlay形式で対話練習することで、実践的な対話スキルが
                  向上します。同時に、RolePlayでの対話内容を定量的に評価するこ
                  とで、ユーザの対話スキルを評価することができます。
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*
        <section className='testimonials text-center bg-light'>
          <div className='container'>
            <h2 className='mb-5'>What people are saying...</h2>
            <div className='row'>
              <div className='col-lg-4'>
                <div className='testimonial-item mx-auto mb-5 mb-lg-0'>
                  <img className='img-fluid rounded-circle mb-3' src='/site/assets/testimonials-1.jpg' alt=''/>
                  <h5>Margaret E.</h5>
                  <p className='font-weight-light mb-0'>"This is fantastic! Thanks so much guys!"</p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='testimonial-item mx-auto mb-5 mb-lg-0'>
                  <img className='img-fluid rounded-circle mb-3' src='/site/assets/testimonials-2.jpg' alt=''/>
                  <h5>Fred S.</h5>
                  <p className='font-weight-light mb-0'>"Bootstrap is amazing. I've been using it to create lots of super nice landing pages."</p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='testimonial-item mx-auto mb-5 mb-lg-0'>
                  <img className='img-fluid rounded-circle mb-3' src='/site/assets/testimonials-3.jpg' alt=''/>
                  <h5>Sarah W.</h5>
                  <p className='font-weight-light mb-0'>"Thanks so much for making these free resources available to us!"</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        <section className='call-to-action text-white text-center'>
          <div className='overlay'></div>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-9 mx-auto'>
                <h2 className='mb-4'>Want to try a sample conversation?</h2>
              </div>
              <div className='col-md-10 col-lg-8 col-xl-7 mx-auto'>
                <form>
                  <div className='form-row'>
                    {/*
                    <div className='col-12 col-md-9 mb-2 mb-md-0'>
                      <input type='email' className='form-control form-control-lg' placeholder='Enter your email...'/>
                    </div>

                    <div className='col-12 col-md-3'>
                    */}
                    <div className='col-4'/>
                    <div className='col-4'>
                      <button type='button' className='btn btn-block btn-lg btn-primary'
                        onClick={this.onStartChat}>
                        Try now!
                      </button>
                    </div>
                    <div className='col-4'/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <footer className='footer bg-light'>
          <div className='container'>
            <div className='row'>

              <div className='col-lg-6 text-center text-lg-center my-auto'>
                <ul className='list-inline mb-2'>
                  {/*
                  <li className='list-inline-item'>
                    <a href='#'>About</a>
                  </li>
                  <li className='list-inline-item'>&sdot;</li>
                  */}
                  <li className='list-inline-item'>
                    <a href={CONTACT_URL}>Contact</a>
                  </li>
                  {/*
                  <li className='list-inline-item'>&sdot;</li>
                  <li className='list-inline-item'>
                    <a href='#'>Terms of Use</a>
                  </li>
                  */}
                  <li className='list-inline-item'>&sdot;</li>
                  <li className='list-inline-item'>
                    <a href={PRIVACY_POLICY_URL}>Privacy Policy</a>
                  </li>
                </ul>
                <p className='text-muted small mb-4 mb-lg-0'>&copy; {APP_NAME} 2018. All Rights Reserved.</p>
              </div>
              
              <div className='col-lg-6 text-center text-lg-center my-auto'>
                <ul className='list-inline mb-0'>
                  <li className='list-inline-item mr-3'>
                    <a href='/' onClick={e => {e.preventDefault(); this.setState({lang:'en'});}}>
                      English
                    </a>
                  </li>
                  <li className='list-inline-item'>&sdot;</li>
                  <li className='list-inline-item mr-3'>
                    <a href='/' onClick={e => {e.preventDefault(); this.setState({lang:'jp'});}}>
                      日本語
                    </a>
                  </li>
                  {/*
                  <li className='list-inline-item mr-3'>
                    <a href='#'>
                      <i className='fa fa-facebook fa-2x fa-fw'></i>
                    </a>
                  </li>
                  <li className='list-inline-item mr-3'>
                    <a href='#'>
                      <i className='fa fa-twitter fa-2x fa-fw'></i>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a href='#'>
                      <i className='fa fa-instagram fa-2x fa-fw'></i>
                    </a>
                  </li>
                  */}
                </ul>
              </div>
              
            </div>
          </div>
        </footer>

        {this.props.prChatKey && <KiwiChat 
          chatKey={this.props.prChatKey}
          hasClose={true}
          onClose={this.onCloseChat}
          />}

      </div>

    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    prChatKey: state.getIn(['prChatKey']),
    rawStore: state.toJS(),
  }),
  (dispatch) => ({ //map dispatch to props
    actions: actions,
  })
)(PRPage)
