import Immutable from 'immutable'
import axios from 'axios'
import shortid from 'shortid'

import * as Store from './store'

import {initialChatState} from './reducer'
import config from '../config'
import WS from '../util/WS'
import Ops from '../util/Ops'
import TimeOps from '../../../commons/TimeOps'

//----------------------------------------------------------------
export const set = Store.set
export const toggle = Store.toggle
export const clean = Store.reset

//----------------------------------------------------------------
export const init = () => {
  // console.log('config', config)

  if(config.isDevelopment) {
    Ops.beep()
  }

  Store.merge({isFetchingUser: true})
  axios.get('/fetch_session', {
    responseType: 'text',
    transitional: {
      forcedJSONParsing: true,
    },
  })
    .then((res) => {
      var {user} = res.data
      // console.log('/fetch_session', user)
      Store.merge({user:user, isFetchingUser: false})
    })
    .catch((error) => {
      console.log(error)
    })

  //remove facebook redirect hash error
  if (window.location.hash && window.location.hash == '#_=_') {
    if (window.history && history.pushState) {
      window.history.pushState('', document.title, window.location.pathname)
    } else {
      // Prevent scrolling by storing the page's current scroll offset
      var scroll = {
        top: document.body.scrollTop,
        left: document.body.scrollLeft
      }
      window.location.hash = ''
      // Restore the scroll offset, should be flicker free
      document.body.scrollTop = scroll.top
      document.body.scrollLeft = scroll.left
    }
  }

}

export const logout = () => {
  axios.post('/logout')
    .then((res) => {
      // console.log('res', res, res.data);
      var {user} = res.data //user should be null here
      Store.merge({user})
    })
    .catch((error) => {
      console.log(error)
    })
}

//----------------------------------------------------------------
export const startKiwiChat = (botKey, customChatOpts=null) => {
  if(!botKey)
    return
  const chatState = initialChatState(botKey, customChatOpts)
  const chatKey = shortid.generate()
  Store.set('chats', chatKey, chatState)
  connectKiwiChat(chatKey)
  return chatKey
}

export const endKiwiChat = (chatKey) => {
  var socket = getChat(chatKey, 'socket').toJS().close()
  Store.remove('chats', chatKey)
}

export const resetKiwiChat = (chatKey) => {
  const botKey = getChat(chatKey, 'botKey')
  const persist = getChat(chatKey, 'persist')
  const socket = getSocket(chatKey)

  // Clear Chat Messages
  Store.merge('chats', chatKey, {
    conversationId: null,
    messages: [],
    kiwiContext: null,
    endContext: null,
    endMessage: null,
  })

  // Initiate Greeting
  Store.set('chats', chatKey, 'job', JOB_PREPARE)
  socket.send('KIWI_GREET', { botKey, persist })
}

const getChat = (chatKey, ...args) => {
  return Store.get('chats', chatKey, ...args)
}

const getSocket = (chatKey) => {
  return Store.get('chats', chatKey, 'socket').toJS()
}

export const connectKiwiChat = (chatKey) => {
  var botKey = getChat(chatKey, 'botKey')
  var persist = getChat(chatKey, 'persist')

  var socket = WS.newSocket()
  Store.set('chats', chatKey, 'socket', socket)
  socket.addMessageHandler({
    KIWI_BOT: (bot) => {
      if(bot) Store.set('chats', chatKey, 'bot', bot)
    },
    KIWI_GREET_STATE: (state) => {
      // console.log('KIWI_GREET_STATE', state, getChat(chatKey, 'job', 'name'))
      if(getChat(chatKey, 'job', 'name') == 'prepare')
        Store.set('chats', chatKey, 'job', 'state', state)
    },
    KIWI_CONVERSATION_ID: (convKey) => {
      // console.log('KIWI_CONVERSATION_ID', convKey)
      Store.set('chats', chatKey, 'conversationId', convKey)
    },
    KIWI_CONVERSE_STATE: (state) => {
      // console.log('KIWI_CONVERSE_STATE', state)
      if(getChat(chatKey, 'job', 'name') == 'converse')
        Store.set('chats', chatKey, 'job', 'state', state)
    },
    KIWI_CONVERSE: (payload) => {
      // console.log('payload', payload)
      var {humanMessage, botMessage} = payload

      if(humanMessage) {
        Store.pop('chats', chatKey, 'messages')
        addChatMessage(chatKey, humanMessage)
      }
      if(botMessage) {
        addChatMessage(chatKey, botMessage)
        var kiwiContext = botMessage.kiwiContext //latest context
        Store.set('chats', chatKey, 'kiwiContext', kiwiContext)
        if(Store.get('sketch', 'chatKey') == chatKey)
          Store.set('sketch', 'kiwiContext', botMessage.kiwiContext)
      }
    },
    KIWI_CONVERSE_END: (payload) => {
      var {kiwiContext, message} = payload
      Store.set('chats', chatKey, 'endContext', kiwiContext)
      Store.set('chats', chatKey, 'endMessage', message)
      if(Store.get('sketch', 'chatKey') == chatKey)
        Store.set('sketch', 'kiwiContext', kiwiContext)
    },
    KIWI_ERROR: (payload) => {
      console.log('KIWI_ERROR', payload)
    }
  })

  // sendChat(chatKey, 'job', JOB_PREPARE)
  Store.set('chats', chatKey, 'job', JOB_PREPARE)
  socket.send('KIWI_GREET', {botKey, persist})
}

const JOB_PREPARE = {
  stateMap: {
    '-1': 'Preparing..',
    '0': 'Error',
    '1': 'Preparing...',
    '2': 'Prepared',
    '3': 'Requires Training',
    '401': 'Permission Denied',
  },
  state: -1,
  name: 'prepare',
}

export const connectKiwiSketch = (botKey) => {
  var socket = WS.newSocket()
  Store.merge('sketch', {
    botKey: botKey,
    socket: socket,
  })

  socket.addMessageHandler({
    KIWI_CELL_DATA: (cellData) => {
      Store.set('sketch', 'cellData', cellData)
    },
    KIWI_BOT: (bot) => {
      // console.log('bot', bot)
      Store.set('sketch', 'bot', bot)
    },
    KIWI_PREPARE_STATE: (state) => {
      // console.log('KIWI_PREPARE_STATE', state)
      if(Store.get('sketch', 'job', 'name') == 'prepare')
        Store.set('sketch', 'job', 'state', state)
    },
    KIWI_TRAIN_STATE: (state) => {
      // console.log('KIWI_TRAIN_STATE', state)
      if(Store.get('sketch', 'job', 'name') == 'train')
        Store.set('sketch', 'job', 'state', state)
    },
    KIWI_ERROR: (payload) => {
      console.log('KIWI_ERROR', payload)
      // if(payload.replyFor == 'KIWI_PREPARE' && payload.errorCode == 401) {
      //   window.location = '/chat'
      // }
    }
  })

  Store.set('sketch', 'job', JOB_PREPARE)
  socket.send('KIWI_PREPARE', {botKey:botKey})
}

export const disconnectKiwiSketch = () => {
  var socket = Store.get('sketch', 'socket')
  if(socket) socket.toJS().close()

  Store.reset('sketch')
}

export const trainKiwi = (botKey, cellData) => {
  var socket = Store.get('sketch', 'socket').toJS()
  Store.set('sketch', 'job', {
    stateMap: {
      '-1': 'Training..',
      '0': 'Error',
      '1': 'Training...',
      '2': 'Trained',
      '401': 'Permission Denied',
    },
    state: -1,
    name: 'train',
  })
  socket.send('KIWI_TRAIN', {
    botKey: botKey,
    cellData: cellData,
    bot: Store.get('sketch', 'bot').toJS(),
  })
}

export const addChatMessage = (chatKey, message) => {
  Store.push('chats', chatKey, 'messages', message)
}

export const dashboardConverse = (message=null) => {
  var chatKey = Store.get('sketch', 'chatKey')
  converse(chatKey, message)
}

export const converse = (chatKey, message=null) => {
  var botKey = getChat(chatKey, 'botKey')
  var conversationId = getChat(chatKey, 'conversationId')
  var message = message || getChat(chatKey, 'message')
  var kiwiContext = getChat(chatKey, 'kiwiContext')
  var persist = getChat(chatKey, 'persist')

  const isLocalhost = location.href.indexOf('localhost') != -1
  if(!message && isLocalhost)
    message = 'hello'

  if(!message)
    return

  Store.set('chats', chatKey, 'message', '')

  var postData = {
    botKey: botKey,
    conversationId: conversationId,
    userTranscript: message,
    timestamp: TimeOps.nowTimestampLocal(),
    kiwiContext: kiwiContext,
    persist: persist,
  }

  addChatMessage(chatKey, {
    type: 'human',
    transcript: message,
    timestamp: postData.timestamp,
    kiwiContext: kiwiContext,
  })

  Store.set('chats', chatKey, 'job', {
    stateMap: {
      '-1': 'Thinking..',
      '0': 'Error',
      '1': 'Thinking...',
      '2': 'Replied',
    },
    state: -1,
    name: 'converse',
  })

  getSocket(chatKey).send('KIWI_CONVERSE', postData)
}



