var setupProto = () => {
  var proto = fabric.LinkSplitControl.prototype
  proto.defaultOptions = () => ({
    stroke: 'black',
    fill: '#000000AA',
    radius: 6,
    hoverCursor: 'pointer',

    hasControls: false,
    hasBorders: false,
    // lockMovementX: true,
    // lockMovementY: true,

    line: null,
  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = true
  proto.shouldPersist = false
  proto.isNatureEditable = false
}

fabric.LinkSplitControl = fabric.util.createClass(fabric.Circle, {
  type: 'LinkSplitControl',
  baseType: 'LinkSplitControl',

  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },

  onMouseDown: function() {
    this.line.split()
  },

})
setupProto()
