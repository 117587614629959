import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Nav from './Nav'
import TimeOps from '../../../commons/TimeOps'

class DocsPage extends React.Component {

  state = {
    greet: {
      postData: {
        botKey: 'test',
        persist: false,
      },
      response: null,
    },
    converse: {
      postData: {
        botKey: 'test',
        conversationId: '',
        userTranscript: 'hi bot',
        timestamp: TimeOps.nowTimestampLocal(),
        kiwiContext: {memory: {}, bearing: 'S15kDHd1X'},
        persist: false,
      },
      response: null,
    },
  }

  doGreet = () => {
    var greet = this.state.greet
    greet.response = 'Request sent, please wait..'
    this.setState({greet})

    axios.post('/api/v1/greet?apiKey=demo', this.state.greet.postData)
      .then(res => {
        var greet = this.state.greet
        greet.response = res.data
        this.setState({greet})
        var converse = this.state.converse
        converse.postData.conversationId = greet.response.conversationId
        converse.postData.kiwiContext = greet.response.botMessage.kiwiContext
        this.setState({converse: {...converse}})
      })
      .catch(res => {
        var greet = this.state.greet
        greet.response = res.response.data
        this.setState({greet})
      })
  }

  doConverse = () => {
    if(!this.state.converse.postData.conversationId) {
      alert('Don\'t forget to add a conversationId!')
      return
    }

    var converse = this.state.converse
    converse.response = 'Request sent, please wait..'
    this.setState({converse})

    axios.post('/api/v1/converse?apiKey=demo', this.state.converse.postData)
      .then(res => {
        var converse = this.state.converse
        converse.response = res.data
        this.setState({converse})
      })
      .catch(res => {
        var converse = this.state.converse
        converse.response = res.response.data
        this.setState({converse})
      })
  }

  onChange = (e, keyString) => {
    var keys = keyString.split('.')
    var obj0 = this.state[keys[0]]
    var o = obj0
    for(let i=1;i<keys.length-1;i++)
      o = o[keys[i]]
    var value = e.target.value
    if(keyString == 'converse.postData.kiwiContext') {
      value = JSON.parse(value)
    }
    o[keys[keys.length-1]] = value
    this.setState({[keys[0]]:obj0})
  }

  render () {
    const {greet, converse} = this.state
    return (
      <div className='docs-page'>
        <Nav/>
        <div className='viewport'>

          <section>
            <header>Greet API</header>
            <var>POST http://chattycoach.com/api/v1/greet?apiKey=<Link to='/profile'>&lt;your_api_key&gt;</Link></var>
            <var>Greet API is the first call to make when starting a conversation.</var>

            <div className='card'>
              <div className='card-header'>Post Data Attributes</div>
              <div className='card-body'>
                <form>
                  <div>
                    <label htmlFor='greet0'>botKey</label>
                    <input id='greet0' 
                      onChange={(e) => this.onChange(e, 'greet.postData.botKey')}
                      value={greet.postData.botKey}/>
                    <small className='form-text text-muted'>ID of the Bot</small>
                  </div>
                  <div>
                    <label htmlFor='greet1'>persist</label>
                    <input id='greet1' value={greet.postData.persist} readOnly/>
                    <small className='form-text text-muted'>(Uneditable in docs page) Set to true to persist chat history in database</small>
                  </div>
                </form>
              </div>
            </div>

            <div className='overheads'>
              <div className='card'>
                <div className='card-header'>
                  <span>Post Data</span>
                  <button className='btn-send' onClick={this.doGreet}>Send Test Greet Request</button>
                </div>
                <textarea readOnly value={JSON.stringify(greet.postData, null, 2)}/>
              </div>
              <div className='card'>
                <div className='card-header'>Response</div>
                <textarea readOnly value={JSON.stringify(greet.response, null, 2)}/>
              </div>
            </div>
          </section>

          <br/><br/><br/>
          <section>
            <header>Converse API</header>
            <var>POST http://chattycoach.com/api/v1/converse?apiKey=<Link to='/profile'>&lt;your_api_key&gt;</Link></var>
            <var>Greet API must be called first to start a conversation.</var>
            <var>If the response contains an 'end' object then the conversation is over and cannot be used anymore.</var>

            <div className='card'>
              <div className='card-header'>Post Data Attributes</div>
              <div className='card-body'>
                <form>
                  <div>
                    <label>botKey</label>
                    <input
                      onChange={(e) => this.onChange(e, 'converse.postData.botKey')}
                      value={converse.postData.botKey}/>
                    <small className='form-text text-muted'>ID of the Bot</small>
                  </div>
                  <div>
                    <label>conversationId</label>
                    <input 
                      onChange={(e) => this.onChange(e, 'converse.postData.conversationId')}
                      value={converse.postData.conversationId}/>
                    <small className='form-text text-muted'>conversationId from Greet API</small>
                  </div>
                  <div>
                    <label>userTranscript</label>
                    <input 
                      onChange={(e) => this.onChange(e, 'converse.postData.userTranscript')}
                      value={converse.postData.userTranscript}/>
                    <small className='form-text text-muted'>User input (what the user said to the bot)</small>
                  </div>
                  <div>
                    <label>timestamp</label>
                    <input 
                      onChange={(e) => this.onChange(e, 'converse.postData.timestamp')}
                      value={converse.postData.timestamp}/>
                    <small className='form-text text-muted'>Timestamp of user input (YYYY-MM-DD[T]HH:mm:ss.SSSZZ)</small>
                  </div>
                  <div>
                    <label>kiwiContext</label>
                    <input 
                      onChange={(e) => this.onChange(e, 'converse.postData.kiwiContext')}
                      value={JSON.stringify(converse.postData.kiwiContext)}/>
                    <small className='form-text text-muted'>kiwiContext (JSON Object) Returned from the Greet API or the last call to Converse API.  Represents current position in sketch.</small>
                  </div>
                  <div>
                    <label>persist</label>
                    <input value={converse.postData.persist} readOnly/>
                    <small className='form-text text-muted'>(Uneditable in docs page) Set to true to persist chat history in database</small>
                  </div>
                </form>
              </div>
            </div>

            <div className='overheads'>
              <div className='card'>
                <div className='card-header'>
                  <span>Post Data</span>
                  <button className='btn-send' onClick={this.doConverse}>Send Test Converse Request</button>
                </div>
                <textarea readOnly value={JSON.stringify(converse.postData, null, 2)}/>
              </div>
              <div className='card'>
                <div className='card-header'>Response</div>
                <textarea readOnly value={JSON.stringify(converse.response, null, 2)}/>
              </div>
            </div>
          </section>


        </div>
      </div>
    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    // callerInput: state.getIn(['home', 'callerInput']),
  }),
  (dispatch) => ({ //map dispatch to props
   actions: actions,
  })
)(DocsPage)