import xlsx from 'xlsx'

var exports = module.exports = {}

const rABS = true //read as binary string

var parseJson = exports.parseJson = (file, onComplete) => {
  var reader = new FileReader()
  reader.onload = function(e) {
    var data = e.target.result
    if(!rABS) data = new Uint8Array(data)
    var workbook = xlsx.read(data, {type: rABS ? 'binary' : 'array'})
    var json = wbToJson(workbook)
    onComplete(json)
  }
  if(rABS)
    reader.readAsBinaryString(file)
  else
    reader.readAsArrayBuffer(file)
}

var wbToJson = (workbook) => {
  var sheetMap = {}
  workbook.SheetNames.forEach(function(sheetName) {
    var roa = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1})
    if(roa.length) sheetMap[sheetName] = roa
  })
  var cells2d = sheetMap[Object.keys(sheetMap)[0]]
  return cells2d
  // console.log(cells2d)
  // var headerRow = cells2d[0]
  // var row1 = cells2d[1]
}

var parseClusters = exports.parseClusters = (file, onComplete) => {
  parseJson(file, (xlsxRows) => {
    // console.log('xlsxRows', xlsxRows)
    
    let riStart = 2 //start at ri 2 to skip headers
    let ciRole = 6
    let ciPattern = 8
    let ciParameter = 9
    let isBotRole = (val) => val == 'A' || val == 'Ａ'
    let clusters = []

    for(let ri=riStart; ri<xlsxRows.length; ri++) {
      let row = xlsxRows[ri]

      if(!row[0] || row[0].indexOf('RolePlay') == -1)
        break

      let role = row[ciRole]
      let pattern = row[ciPattern]
      let parameter = row[ciParameter]
      let parameterNature = null
      if(parameter) {
        let re = new RegExp('([a-zA-Z]*)([=|+|\-|>|<]+)(.*)').exec(parameter)
        parameterNature = {name:re[1], operation: re[2], value:re[3]}
      }

      let type = isBotRole(role) ? 'BotNode' : 'HumanNode'
      // console.log(type, pattern, parameter)

      if(isBotRole(role)) {
        clusters.push({botPatterns: [pattern], cells:[]})
      }
      else {
        let activeCluster = clusters[clusters.length-1]
        let lastCell = activeCluster.cells[activeCluster.cells.length-1]
        if(lastCell && lastCell.parameter == parameter) {
          lastCell.patterns.push(pattern)
        }
        else {
          activeCluster.cells.push({patterns:[pattern], parameter, parameterNature})
        }
      }
    }

    // console.log(clusters)
    onComplete(clusters)
  })
}

exports.addCells = (sketch, file) => {
  parseClusters(file, (clusters) => {
    var trailingCells = []
    for(let i=0; i<clusters.length; i++) {
      var cluster = clusters[i]

      var botNode = sketch.addNewCell(fabric.BotNode, {
        nature: {patterns:cluster.botPatterns},
        left: sketch._fc.getVpCenter().x + 500*i,
        top: sketch._fc.getVpCenter().y,
      })

      for(let tCell of trailingCells)
        sketch.addLink(tCell, botNode)
      trailingCells = []

      for(let j=0; j<cluster.cells.length; j++) {
        let cell = cluster.cells[j]
        var humanNode = sketch.addNewCell(fabric.HumanNode, {
          nature: {patterns:cell.patterns},
          left: sketch._fc.getVpCenter().x + 500*i+160,
          top: sketch._fc.getVpCenter().y + 120*j,
        })
        sketch.addLink(botNode, humanNode)

        var parameterNode = null
        if(cell.parameterNature) {
          parameterNode = sketch.addNewCell(fabric.ParameterNode, {
            nature: cell.parameterNature,
            left: sketch._fc.getVpCenter().x + 500*i+160+160,
            top: sketch._fc.getVpCenter().y + 120*j,
          })
          sketch.addLink(humanNode, parameterNode)
        }

        trailingCells.push(parameterNode || humanNode)
      }
    }
    
    sketch._fc.renderAll()
  })
}
