const NODE_ENV = process.env.NODE_ENV

const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches

export default {
  NODE_ENV: NODE_ENV,
  isProduction: NODE_ENV == 'production',
  isDevelopment: NODE_ENV == 'development',

  isMobile: isMobile,

  defaultCharacterIcon: '/site/assets/sakura-face-64x64.png',
}