import React from 'react'
import axios from 'axios'
import Messages from './Messages'
import Ops from '../util/Ops'
import TimeOps from '../../../commons/TimeOps'
import Nav from './Nav'
import { Link } from 'react-router-dom'

class ProfilePage extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 0,
      bots: [],
      activeBot: null,
      conversations: [],
      activeConversation: null,
      botConversations: [],
      activeBotConversation: null,
      score: null,
      user: null,
      apiKey: null,
    }
  }

  componentDidMount() {
    axios.get(`/user/${this.props.user.get('id')}/bots`)
      .then(res => {
        // console.log('res', res)
        var bots = res.data.bots
        this.setState({
          bots: bots, 
          activeBot: bots.length > 0 ? bots[0] : null,
        }, bots.length > 0 ? this.fetchBotConvs : null)
      })

    axios.get(`/user/${this.props.user.get('id')}/conversations`)
      .then(res => {
        // console.log('res.data', res.data)
        var conversations = res.data.conversations
        this.setState({
          conversations: conversations,
          activeConversation: conversations.length > 0 ? conversations[0] : null,
        })
      })

    axios.get(`/user/${this.props.user.get('id')}/score`)
      .then(res => {
        var score = res.data.score
        this.setState({
          score: score,
        })
      })

    axios.get(`/user/api-key`)
      .then(res => {
        var apiKey = res.data.apiKey
        this.setState({apiKey})
      })

    axios.get(`/user`)
      .then(res => {
        var user = res.data
        this.setState({user})
      })
  }

  fetchBotConvs = () => {
    axios.get(`/bot/${this.state.activeBot.botKey}/conversations`)
      .then(res => {
        var conversations = res.data.conversations
        // console.log('res.data', res.data)
        this.setState({
          botConversations: conversations,
          activeBotConversation: conversations.length > 0 ? conversations[0] : null,
        })
      })
  }

  createApiKey = () => {
    axios.post('/user/api-key')
      .then(res => {
        var apiKey = res.data.apiKey
        this.setState({apiKey})
      })
  }

  removeUser = () => {
    if(confirm('Are you sure?')) {
      axios.delete('/users')
        .then(res => {
          this.setState({})
          this.context.router.history.replace('/')
        })
    }
  }

  render () {
    const {activeTab, conversations, activeConversation} = this.state
    // console.log('activeConversation', activeConversation, 'activeBot', activeBot)

    return (
      <div className='profile'>

        <Nav/>

        <div className='hbox flow1 span-stretch'>

          <div className='sidebar'>
            <div className='profile-well'>
              <div className='header'> {this.props.user.get('name')} </div>
              {Ops.isNum(this.state.score) &&
                <div className='score'>
                  {'Score: ' + Math.round(this.state.score*100) + '%'}
                </div>
              }
            </div>

            <ul className='sidebar-nav'>
              <li className={(activeTab==0?'active':'')} onClick={() => this.setState({activeTab:0})}><a>My Profile</a></li>
              <li className={(activeTab==1?'active':'')} onClick={() => this.setState({activeTab:1})}><a>My Bots</a></li>
              <li className={(activeTab==2?'active':'')} onClick={() => this.setState({activeTab:2})}><a>My Conversations</a></li>
            </ul>
          </div>

          <div className='viewport'>
            {activeTab==0 && this.renderProfileView()}
            {activeTab==1 && this.renderBotsView()}
            {activeTab==2 && this.renderConvosView(conversations, activeConversation, (conv) => this.setState({activeConversation: conv}))}
          </div>

        </div>

      </div>
    )
  }

  renderProfileView = () => {
    const {user, apiKey} = this.state
    const name = (user && user.name) || '?'
    const email = (user && user.email) || '?'

    return (
      <div className='profile-view'>
        <div className='profile-card'>
            <div className='profile-card-header'>Profile</div>

            <div className='profile-card-body'>
              <div className='user-props'>
                <div><label>Name:</label><span>{name}</span></div>
                <div><label>Email:</label><span>{email}</span></div>
              </div>
              <div className='user-actions'>
                <div className='btn btn-danger' onClick={this.removeUser}>
                  Remove Profile
                </div>
              </div>
            </div>

        </div>
        <div className='dev-card'>
            <div className='dev-card-header'>Developers</div>

            <div className='dev-card-body'>
              <Link to='/docs' className='btn btn-primary'>View API Documentation</Link>
              <br/>

              <div className='hbox ispan-stretch'>
                <div>API Key</div>
                <input className='form-control' type='text' value={apiKey || ''} placeholder='No key' readOnly/>
              </div>
              <br/>

              <div className='btn btn-primary' onClick={this.createApiKey}>
                Create New API Key
              </div>
            </div>

        </div>
      </div>
    )
  }

  renderBotsView = () => {
    const {bots, activeBot, botConversations, activeBotConversation} = this.state
    if(!bots) return null

    return (
      <div className='bot-view'>
        <div className='view-header'>Bots</div>

        <div className='view-body'>

          <ul className='bots-list'>
            {bots.map(bot => {
              return (
                <li 
                  className={(activeBot==bot?'active':'')}
                  onClick={() => this.setState({activeBot: bot}, this.fetchBotConvs)}
                  key={bot.botKey}
                  >
                  {bot.name || bot.botKey}
                </li>
              )
            })}
          </ul>

          {activeBot && (
            <div className='bot-details'>

              <div className='bot-details-header'>
                <h4>{activeBot.name || activeBot.botKey}</h4>
                <div className='bot-details-actions'>
                  <a href={`/chat/${activeBot.botKey}`} className='btn'>Open Chat</a>
                  <a href={`/sketch/${activeBot.botKey}`} className='btn'>Open Sketch</a>
                </div>
              </div>

              {this.renderConvosView(botConversations, activeBotConversation, (conv) => this.setState({activeBotConversation: conv}))}
              
            </div>
          )}

        </div>
      </div>
    )
  }

  renderConvosView = (convos, activeConvo, onSelectConv) => {
    if(!convos) return null

    return (
      <div className='convos-view'>

        <div className='view-header'>Conversations</div>

        <div className='view-body'>

          <ul className='convos-list'>
            {convos.map(conv => {
              return (
                <li 
                  className={(activeConvo==conv?'active':'')}
                  onClick={() => onSelectConv(conv)}
                  key={conv.id}
                  >
                  {TimeOps.timestampToReadable(conv.updatedAt, 'DD/MM/YYYY HH:mm')}
                </li>
              )
            })}
          </ul>

          {activeConvo && 
            <div className='kiwi-chat'>
              <Messages 
                showFeedback={true}
                messages={activeConvo.messages}
                description={activeConvo.description}
                endContext={activeConvo.endContext}
                endMessage={activeConvo.endMessage}
                />
            </div>
          }
        </div>

      </div>
    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
export default connect(
  (state) => ({ //map store to props
    user: state.get('user'),
  }),
  (dispatch) => ({ //map dispatch to props
     actions: actions,
  })
)(ProfilePage)
