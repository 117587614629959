/*eslint no-unused-vars: 0*/
'use strict';

import FabricCanvasTool from './fabrictool'

class Select extends FabricCanvasTool {

    configureCanvas(props) {
        let canvas = this._canvas;
        canvas.isDrawingMode = false;
        canvas.selection = true;
        canvas.forEachObject((o) => {
          if(o.isEventable)
            o.evented = true
          else
            o.evented = false

          if(o.isSelectable)
            o.selectable = true
          else
            o.selectable = false
        })
    }

}

export default Select