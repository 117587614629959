var setupProto = () => {
  var proto = fabric.PortFailRect.prototype
  proto.defaultOptions = () => ({

  })

  proto.isDependant = true
  proto.isSelectable = false
  proto.isEventable = false
  proto.shouldPersist = false
  proto.isNatureEditable = false
}

fabric.PortFailRect = fabric.util.createClass(fabric.Rect, {
  type: 'PortFailRect',
  baseType: 'PortFailRect',
  
  initialize: function(options) {
    options = this.preInit(options)
    this.callSuper('initialize', options)
  },
})
setupProto()