export default () => (
  <div className='container-fluid container-flex' style={{whitespace:'pre'}}>
      <h2>プライバシーポリシー</h2>

      <p>
      Akademia-Botアプリ（以下、当サイトといいます）が定義する個人情報とは？
      個人情報とは、生存する個人に関する情報であって当該情報に含まれる氏名、住所、メールアドレス、その他の記述または個人別に付与された番号や記号を示します。
      </p>

      <p>
      ■個人情報の利用目的について
      ご提供いただいた個人情報は、Akademia-Botアプリ運営会社であるアカデミアシステムズ株式会社（以下、当社といいます）が、以下の目的で利用するものとします。
      <ol>
        <li>ご利用者の承諾に基づき、当社のパートナーとなりうる企業、広告主、その他の第三者に当社のサービスを説明する場合、その他の合法的な目的のため</li>
        <li>ご利用者の承諾に基づき、広告主にターゲットを絞った効果的な広告手段を提供するため</li>
        <li>統計分析のため</li>
      </ol>
      </p>

      <p>
      ■個人情報の活用範囲について
      ご提供いただいた個人情報は、当社が、以下の範囲で利用するものとします。
      <ol>
        <li>メールマガジン配信および広告の表示</li>
        <li>友人紹介・承認、コメントの書き込み、新着日記が到着した旨のご連絡</li>
        <li>各種レコメンデーション</li>
        <li>プレゼント等各種キャンペーンに関するご連絡</li>
        <li>入力した情報がご本人であるかの確認</li>
        <li>ご請求もしくはご購入いただいた資料・サンプル・商品等の送付</li>
        <li>その他当サイトを通じたサービスの提供</li>
      </ol>
      </p>

      <p>
      ■個人情報の第三者への開示について
      当社は、ご利用者ご本人の同意を得ずに、ご入力いただいた個人情報を第三者に開示することはいたしません。但し、以下のような場合、個人の情報を開示することができるものとします。
      <ol>
        <li>人の生命、身体、財産の保護のために必要がある場合であって、利用者本人の同意を得ることが困難である場合</li>
        <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
        <li>利用者が第三者に不利益を及ぼすと当社判断した場合、提供情報および個人情報を当該.　第三者や警察または関連諸機関に通知することができます。</li>
        <li>裁判所、検察庁、警察、弁護士会、消費者センターまたはこれらに準じた権限を有する機関から、ユーザーからの提供情報およびID登録内容、登録プロフィールについての開示を求められた場合、権利や財産を保護する目的で開示することがあります。</li>
        <li>その他の法令により当社による開示または提供義務が定められている場合</li>
        <li>当サイトでサービスを提供している、商品の販売や広告主への資料請求などのページで、　ご利用者の意志で、個人情報を記入し商品の購買や資料の請求を行なった場合、ご本人の同意とみなし、商品販売主や広告主に個人の情報を開示します。</li>
        <li>当社が営業の全部または一部を第三者に譲渡するか、あるいは分社化する場合で当社から営業譲渡を受けた第三者または分社した会社に譲渡する場合</li>
      </ol>
      </p>

      <p>
      ■ご登録いただいた個人情報の内容を変更するには？
      個人情報の照会、修正等を希望される場合には、ログイン後、設定＞プロフィール編集より照会・変更手続きをお取りいただけます。そこで変更できないものについては、原則的に変更できません。
      </p>

      <p>
      ■統計データの利用について
      当社は、提供を受けた個人情報をもとに、個人を特定できない形式による統計データを作成し、当該データにつき何らの制限なく利用することができるものとします。
      </p>

      <p>
      ■インターネット上のプライバシーについて、他に確認しておくべきこと
      インターネット上で自発的に個人情報を開示した場合、その情報は他の利用者によって収集され使用される可能性があることに留意してください。誰もがアクセスできるインターネット上に個人情報を公開すれば、そこに掲示された情報をもとに、望ましくないメッセージを受け取ることがあります。また、当社のパートナーや、当サイトを通じてユーザーアクセスできる第三者企業のサイトおよびサービスについては独立した個々のプライバシー規定やデータ収集規定を持っています。当サイトはこれらの独立した規約や活動に対していかなる義務や責任も負いかねます。
      </p>

      <p>
      ■変更および通知について
      当社は、この「プライバシーポリシー（個人情報の取扱いについて）」の内容を、事前の予告なく変更することがあります。利用者の方へその都度ご連絡はいたしかねますので、ご利用の際には本ページの最新の内容をご参照ください。
      </p>

      <p>
      Copyright © 2013： アカデミアシステムズ株式会社
      </p>
    </div>
)