import React from 'react'

import { Router, Route, Switch, Link, Redirect} from 'react-router-dom'
import KiwiHome from './KiwiHome'
import ProfilePage from './ProfilePage'
import SketchPage from './SketchPage'
import AuthPage from './AuthPage'
import AdminPage from './AdminPage'
import DocsPage from './DocsPage'
import PrivacyPolicy from './PrivacyPolicy'
import PRPage from './PRPage'
import config from '../config'

import createHistory from 'history/createBrowserHistory';
var history = createHistory()

history.listen((location, action) => {
  if(config.isProduction && window.ga) {
    // console.log('ga send', location.pathname)
    window.ga('set', 'page', location.pathname) //update tracker with new page url
    window.ga('send', 'pageview') //send pageview event
  }

  //modify canonical link
  document.querySelector('link[rel="canonical"]').setAttribute('href', 'https://www.chattycoach.com' + location.pathname)
})

class MyRouter extends React.Component {

  render () {
    if(this.props.isFetchingUser) {
      return (<div>loading..</div>)
    }

    return (
      <Router history={history}>
        
          <Switch>
            <Route exact={true} path='/' component={PRPage}/>

            <Route exact={false} path='/chat/:botKey?' component={KiwiHome}/>
            <PrivateRoute path='/sketch/:botKey' component={SketchPage}/>

            <Route path='/auth' component={AuthPage}/>
            <PrivateRoute path='/profile' component={ProfilePage}/>
            <PrivateRoute path='/admin' component={AdminPage} level={ROLE_ADMIN}/>
            <Route path='/privacy-policy' component={PrivacyPolicy}/>


            <Route path='/docs' component={DocsPage}/>

            <Redirect path='*' to='/'/>
          </Switch>
          
      </Router>
    )
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../redux/actions'
MyRouter = connect(
  (state) => ({ //map store to props
    isFetchingUser: state.get('isFetchingUser'),
    // user: state.get('user'),
  }),
  (dispatch) => ({ //map dispatch to props
     actions: actions,
  })
)(MyRouter)
export default MyRouter



// var PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     props.user
//       ? <Component {...props} />
//       : <Redirect to='/auth' />
//   )} />
// )

const ROLE_ADMIN = 'admin'

class PrivateRoute extends React.Component {
  isAuthorized = () => {
    if(this.props.level == ROLE_ADMIN)
      return this.props.user && this.props.user.get('role') == ROLE_ADMIN
    return !!this.props.user
  }

  componentDidMount() {
    // console.log('this.props', this.props)
    if(!this.isAuthorized()) {
      var {pathname, hash, search} = this.props.location
      var url = pathname + hash + search
      this.props.actions.set('authReferrer', url)
    }
  }

  render() {
    var { component: Component, user, ...rest } = this.props
    return ( this.isAuthorized() ? <Route component={Component} {...rest} /> : <Redirect to='/auth'/> )
  }
}

PrivateRoute = connect(
  (state) => ({ //map store to props
    user: state.get('user'),
  }),
  (dispatch) => ({ //map dispatch to props
     actions: actions,
  })
)(PrivateRoute)