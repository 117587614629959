/*eslint no-unused-vars: 0*/
'use strict';

import React, {PureComponent} from 'react'
// import PropTypes from 'prop-types'
import History from './history'
import Select from './tools/select'
import Pencil from './tools/pencil'
// import Line from './tools/line'
// import Rectangle from './tools/rectangle'
// import Circle from './tools/circle'
import Pan from './tools/pan'
import Tool from './tools/tools'
// import Text from './tools/text'
import Ops from '../../util/Ops'

const fabric = require('fabric').fabric
import {} from './model/FabricObject'
import {} from './model/Hook'
import {} from './model/HookRemoveControl'
import {} from './model/HookHeadArrow'
import {} from './model/Link'
import {} from './model/LinkLine'
import {} from './model/LinkSplitControl'
import {} from './model/Node'
import {} from './model/NodeRemoveControl'
import {} from './model/NodeNatureLabel'
import {} from './model/Port'
import {} from './model/PortFailRect'

/**
 * Sketch Tool based on FabricJS for React Applications
 */
class Sketch extends PureComponent {

    setBearing = (bearing) => {
        // console.log('setBearing', bearing)
        this._fc.forEachObject(o => {
            if(o.setBearingStyle) o.setBearingStyle(o.id == bearing)
        })
        this._fc.renderAll()
    }

    addNewCell = (klass, options={}) => {
        var defaultOptions = {
            left: canvas.getVpCenter().x,
            top: canvas.getVpCenter().y,
        }
        options = Object.assign(defaultOptions, options)
        var cell = new klass(options)
        this._fc.add(cell)
        return cell
    }

    addLink = (fromNode, toNode) => {
        let link = this.addNewCell(fabric.Link)
        fromNode.outPort.attachHook(link.tailHook())
        toNode.inPort.attachHook(link.headHook())
    }

    state = {
        parentWidth: 550,
        actionsFromUser: true,
    }

    _initTools = (fabricCanvas) => {
        this._tools = {}
        this._tools[Tool.Select] = new Select(fabricCanvas)
        this._tools[Tool.Pencil] = new Pencil(fabricCanvas)
        // this._tools[Tool.Line] = new Line(fabricCanvas)
        // this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas)
        // this._tools[Tool.Circle] = new Circle(fabricCanvas)
        this._tools[Tool.Pan] = new Pan(fabricCanvas)
        // this._tools[Tool.Text] = new Text(fabricCanvas)
    }

    /**
     * Enable touch Scrolling on Canvas
     */
    // enableTouchScroll = () => {
    //     let canvas = this._fc;
    //     if (canvas.allowTouchScrolling) return;
    //     canvas.allowTouchScrolling = true
    // };

    /**
     * Disable touch Scrolling on Canvas
     */
    // disableTouchScroll = () => {
    //     let canvas = this._fc;
    //     if (canvas.allowTouchScrolling) {
    //         canvas.allowTouchScrolling = false
    //     }
    // };

    /**
     * Add an image as object to the canvas
     *
     * @param dataUrl the image url or Data Url
     * @param options object to pass and change some options when loading image, the format of the object is:
     *
     * {
     *   left: <Number: distance from left of canvas>,
     *   top: <Number: distance from top of canvas>,
     *   scale: <Number: initial scale of image>
     * }
     */
    // addImg = (dataUrl, options = {}) => {
    //     let canvas = this._fc;
    //     fabric.Image.fromURL(dataUrl, (oImg) => {
    //         let opts = {
    //             left: Math.random() * (canvas.getWidth() - oImg.width * 0.5),
    //             top: Math.random() * (canvas.getHeight() - oImg.height * 0.5),
    //             scale: 0.5
    //         };
    //         Object.assign(opts, options);
    //         oImg.scale(opts.scale);
    //         oImg.set({
    //             'left': opts.left,
    //             'top': opts.top
    //         });
    //         canvas.add(oImg);
    //     });
    // }

    _onObjectAdded = (e) => {
        // console.log('Sketch::_onObjectAdded', e.target.type)
        let obj = e.target

        //TODO how to handle links with undo/redo????
        //canvas.add(new fabric.Link([1,2,3,4]))

        // console.log(obj.type)
        obj.version = 1;
        // record current object state as json and save as originalState
        let objState = obj.toJSON();
        obj.originalState = objState;
        let state = JSON.stringify(objState);

        if(this.state.actionsFromUser && !obj.isDependant)
            this._history.keep([obj, state, state])
    }

    _onObjectModified = (e) => {
        let obj = e.target
        // console.log('Sketch::_onObjectModified:', obj.type)
        obj.version += 1
        let prevState = JSON.stringify(obj.originalState)
        let curState = obj.toJSON()
        // record current object state as json and update to originalState
        obj.originalState = curState
        this._history.keep([obj, prevState, JSON.stringify(curState)])
    }

    _onObjectRemoved = (e) => {
        let obj = e.target
        // console.log('Sketch::_onObjectRemoved', obj.type)
        // obj.version = 0

        // if(this.state.actionsFromUser && !obj.isDependant) {
        if(this.state.actionsFromUser && obj instanceof fabric.Node) { //TODO handling removing links
            obj.version += 1
            this._history.keep([obj, null, null])
        }
    }


    /**
     * Action when the mouse button is pressed down
     */
    _onMouseDown = (o) => {
        // console.log('onMouseDown', o.target?o.target.type:'no-type', o.target?o.target.__corner:'no-corner', o)
        this._selectedTool.doMouseDown(o);
        let canvas = this._fc;

        if(this._selectedTool instanceof Select) {
            if(o.target instanceof fabric.OutPort) {
                var port = o.target
                var pointer = canvas.getPointer(o.e);
                var link = new fabric.Link({
                    hookCoords: [{x:pointer.x, y:pointer.y}, {x:pointer.x, y:pointer.y}],
                })
                canvas.add(link)
                this.tracingHook = link.headHook()
                this._fc.selection = false //turn off selection mode while tracing
                port.attachHook(link.tailHook())
            }
            else if(o.target instanceof fabric.LinkSplitControl) {
                this.clickedLinkSplitControl = true
                this._fc.selection = false
                // console.log('tracing hook set', this.tracingHook)
            }
        }
    };

    /**
     * Action when the mouse cursor is moving around within the canvas
     */
    _onMouseMove = (o) => {
        // console.log('_onMouseMove', o, o.target?o.target.type:'no-target')
        this._selectedTool.doMouseMove(o)
        var pointer = canvas.getPointer(o.e) //{x:int, y:int}

        if(this.clickedLinkSplitControl) {
            this.clickedLinkSplitControl = false
            this.tracingHook = fabric.Link.latestNeutralHook
        }

        // console.log('this.tracingHook', this.tracingHook)
        if(this.tracingHook) {
            this.tracingHook.setaPos(pointer)
            this.tracingHook.trigger('moving', o)
            this._fc.renderAll()
        }

        this._fc.forEachObject(o => {
            //TODO why do I need to check if o is undefined, it should always be defined
            //this wierd behavior started when i was working on link remove button
            if(o && o.onCanvasMouseMove) { 
                o.onCanvasMouseMove(pointer)
            }
        })

        // if(o.target && this._selectedTool instanceof Select) {
        //     if(o.target.onMouseMove)
        //         o.target.onMouseMove(o)
        // }
    };

    _onMouseUp = (o) => {
        this._selectedTool.doMouseUp(o);
        // Update the final state to new-generated object
        // Ignore Path object since it would be created after mouseUp
        // Assumed the last object in canvas.getObjects() in the newest object
        if (this.props.tool !== Tool.Pencil) {
            const canvas = this._fc;
            const objects = canvas.getObjects();
            const newObj = objects[objects.length - 1];
            if (newObj && newObj.version === 1) {
                newObj.originalState = newObj.toJSON();
            }
        }
        if (this.props.onChange) {
            let onChange = this.props.onChange;
            setTimeout(() => {
                onChange(o.e)
            }, 10)
        }

        if(this.tracingHook) {
            this._fc.selection = true //turn selection mode back on
            this.tracingHook = null
        }
        this.clickedLinkSplitControl = false //just to be safe
    }

    /**
     * Action when the mouse cursor is moving out from the canvas
     */
    _onMouseOut = (o) => {
        // console.log('_onMouseOut', o, o.target?o.target.type:'no-target')
        this._selectedTool.doMouseOut(o);
        if (this.props.onChange) {
            let onChange = this.props.onChange;
            setTimeout(() => {
                onChange(o.e)
            }, 10)
        }
    }

    _onSelectionCreated = (e) => {
        // console.log('selection:created', e, e.target instanceof fabric.Group)

        var selectedObject = e.target
        if(selectedObject instanceof fabric.Group) {
            var group = selectedObject
            group.set({
                hasControls: false,
                // lockScalingX: true,
                // lockScalingY: true,
                // hasRotatingPoint: false,
                // dirty: true,
                hasBorders: false,
            })
        }
        // var isGroup = !!e.forEachObject

        // if(isGroup) {
        //     var group = e.target
        //     group.on('object:moving', (e) => {
        //         group.forEachObject((o) => {
        //             o.setCoords()
        //         })
        //     })
        // }
    }

    _onSelectionCleared = (e) => {
        // console.log('selection:cleared', e, canvas.getActiveObject())
        
        // prevSelected.forEachObject(o => {
        //need to update coords after group is unselected
        canvas.forEachObject(o => {
            if(o) //TODO why do i need to check this
                o.setCoords()
        })
    }

    _onKeyDown = (e) => {
        if(!(e.target == document.body)) //not focusing another input
            return
        // console.log('keydown', e, e.charCode, e.char, e.key, e.keyCode, e.shiftKey, e.ctrlKey, e.metaKey)
        if((e.ctrlKey || e.metaKey) && e.key.toLowerCase() == 'c') {
          this.copy()
        }
        else if((e.ctrlKey || e.metaKey) && e.key.toLowerCase() == 'v') {
          this.paste()
        }
        else if((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() == 'z') {
          this.redo()
        }
        else if((e.ctrlKey || e.metaKey) && e.key.toLowerCase() == 'z') {
          this.undo()
        }
        else if(e.key.toLowerCase() == 'delete') {
            // console.log(this._fc.getActiveObject(), this._fc.getActiveGroup())
            var group = this._fc.getActiveGroup()
            var object = this._fc.getActiveObject()
            if(group) {
                group.forEachObject(o => o.remove())
            }
            else if(object) {
                object.remove()
            }
        }
        // else if(e.key.toLowerCase() == 'shift') {
        //     this.isShiftDown = true
        // }
    }

    _onKeyUp = (e) => {
        if(!(e.target == document.body)) //not focusing another input
            return

        // if(e.key.toLowerCase() == 'shift') {
        //     this.isShiftDown = false
        // }
    }

    _onMouseWheel = (e) => {
        e.preventDefault()
        e = e.originalEvent || e

        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)))
        var sensitivity = 0.1
        this.zoomBy(delta*sensitivity)
    }

    /**
     * Track the resize of the window and update our state
     *
     * @param e the resize event
     * @private
     */
    _resize = (e) => {
        if (e) e.preventDefault();
        let {widthCorrection, heightCorrection} = this.props;
        let canvas = this._fc;
        let {offsetWidth, clientHeight} = this._container;
        let prevWidth = canvas.getWidth();
        let prevHeight = canvas.getHeight();
        let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
        let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
        canvas.setWidth(offsetWidth - widthCorrection);
        canvas.setHeight(clientHeight - heightCorrection - 1); // -1 for rounding bug causing vertical scrollbar to appear
        // if (canvas.backgroundImage) {
        //     // Need to scale background images as well
        //     let bi = canvas.backgroundImage;
        //     bi.width = bi.width * wfactor;
        //     bi.height = bi.height * hfactor
        // }
        // let objects = canvas.getObjects();
        // for (let i in objects) {
        //     let obj = objects[i];
        //     let scaleX = obj.scaleX;
        //     let scaleY = obj.scaleY;
        //     let left = obj.left;
        //     let top = obj.top;
        //     let tempScaleX = scaleX * wfactor;
        //     let tempScaleY = scaleY * hfactor;
        //     let tempLeft = left * wfactor;
        //     let tempTop = top * hfactor;
        //     obj.scaleX = tempScaleX;
        //     obj.scaleY = tempScaleY;
        //     obj.left = tempLeft;
        //     obj.top = tempTop;
        //     obj.setCoords()
        // }
        // this.setState({
        //     parentWidth: offsetWidth
        // });
        canvas.renderAll();
        canvas.calcOffset();
    };

    // /**
    //  * Sets the background color for this sketch
    //  * @param color in rgba or hex format
    //  */
    // _backgroundColor = (color) => {
    //     if (!color) return;
    //     let canvas = this._fc;
    //     canvas.setBackgroundColor(color, () => canvas.renderAll())
    // };

    /**
     * Zoom the drawing by the factor specified
     *
     * The zoom factor is a percentage with regards the original, for example if factor is set to 2
     * it will double the size whereas if it is set to 0.5 it will half the size
     *
     * @param factor the zoom factor
     */
    zoomTo = (factor) => {
        //TODO
    }

    //http://fabricjs.com/fabric-intro-part-5#pan_zoom
    zoomBy = (dZ) => {
        let canvas = this._fc;
        // let objects = canvas.getObjects();
        // for (let i in objects) {
        //     objects[i].scaleX = objects[i].scaleX * factor;
        //     objects[i].scaleY = objects[i].scaleY * factor;
        //     // objects[i].left = objects[i].left * factor;
        //     // objects[i].top = objects[i].top * factor;
        //     // objects[i].setCoords();
        // }
        // canvas.forEachObject((o) => {
        //     o.setCoords() //have to do set coords after all objects have been modified
        // })
        // canvas.renderAll();
        // canvas.calcOffset();

        //TODO USE canvas.getVpCenter()

        var curZoom = (this._zoomFactor || 1.0)
        var newZoom = curZoom + dZ
        if(newZoom < 0.2)
            newZoom = 0.2
        else if(newZoom > 1.6)
            newZoom = 1.6

        if(curZoom == newZoom)
            return
        this._zoomFactor = newZoom

        //calculate offset x & y to zoom centered
        var c0 = canvas.vptCoords //{tl: Point, br: Point, tr: Point, bl: Point}
        var curWidth = c0.tr.x - c0.tl.x
        var curHeight = c0.bl.y - c0.tl.y
        var curOffsetX = c0.tl.x
        // console.log('cur', curWidth)

        var origWidth = curWidth * curZoom
        var origHeight = curHeight * curZoom
        var origCx = origWidth / 2
        var origCy = origHeight / 2
        // console.log('orig', origWidth, origCx)

        var newWidth = origWidth / newZoom
        var newHeight = origHeight / newZoom
        var newCx = newWidth / 2
        var newCy = newHeight / 2
        // console.log('new', newWidth, newCx)

        var origOffset = curOffsetX * curZoom
        var newOffset = origOffset / newZoom
        //TODO offset
        // console.log(curOffsetX, newOffset, curZoom, newZoom)
 
        var dx = (newCx - origCx) * newZoom //+x moved objects to right
        var dy = (newCy - origCy) * newZoom
        // console.log(dx)
        
        canvas.setViewportTransform([ this._zoomFactor, 0, 0, this._zoomFactor, dx, dy ])
    }

    copy = () => {
        // clone what are you copying since you
        // may want copy and paste on different moment.
        // and you do not want the changes happened
        // later to reflect on the copy.
        var canvas = this._fc
        var activeObject = canvas.getActiveObject()
        var activeGroup = canvas.getActiveGroup()

        if(!activeObject && !activeGroup) {
            //alert('Oops, nothing to copy.')
            console.log('Sketch.copy():: no active canvas object to copy')
            return
        }

        if(activeGroup) {
            this._clipboard = []
            activeGroup.forEachObject(o => {
                if(o instanceof fabric.Node) {
                    var clone = o.clone()
                    clone.left = o.getaCenterPoint().x
                    clone.top = o.getaCenterPoint().y
                    this._clipboard.push(clone)
                }
            })
            return
        }

        if(activeObject instanceof fabric.Node) {
            this._clipboard = activeObject.clone()//activeObject.toJSON({includeIds:false})
            return
        }
    }

    paste = () => {
        if(!this._clipboard) {
            console.log('Sketch.paste():: nothing to paste')
            //alert('Oops, nothing to paste.')
            return
        }
        var canvas = this._fc

        if(Array.isArray(this._clipboard)) {
            canvas.deactivateAll().renderAll();

            var clones = []
            this._clipboard.forEach(o => {
                var cloned = o.clone()
                cloned.left += 10
                cloned.top += 10
                canvas.add(cloned)
                o.top += 10
                o.left += 10
                clones.push(cloned)
                cloned.set('active', true)
            })
            var group = new fabric.Group(clones, {
                originX: 'center',
                originY: 'center'
            })
            canvas._activeObject = null
            group.setCoords()
            canvas.setActiveGroup(group)
        }
        else {
            var cloned = this._clipboard.clone()
            cloned.left += 10
            cloned.top += 10
            canvas.add(cloned)
            this._clipboard.top += 10
            this._clipboard.left += 10
            canvas.setActiveObject(cloned)
        }

        // var canvas = this._fc
        // // clone again, so you can do multiple copies.
        // var cloned = this._clipboard.clone()
        // console.log('Sketch.paste():: cloned:', cloned)
        // canvas.discardActiveObject();
        // cloned.set({
        //     left: cloned.left + 10,
        //     top: cloned.top + 10,
        //     evented: true,
        // });
        // if (cloned.type === 'activeSelection') {
        //     // active selection needs a reference to the canvas.
        //     cloned.canvas = canvas;
        //     cloned.forEachObject((obj) => {
        //         canvas.add(obj);
        //     });
        //     // this should solve the unselectability
        //     cloned.setCoords();
        // } else {
        //     canvas.add(cloned);
        // }
        // this._clipboard.top += 10;
        // this._clipboard.left += 10;
        // canvas.setActiveObject(cloned);
        canvas.renderAll();//canvas.requestRenderAll();
    }

    /**
     * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
     */
    undo = () => {
        let history = this._history;
        if(!history.canUndo()) {
            console.log('Sketch.undo(): nothing to undo')
            return
        }

        let [obj, prevState, currState] = history.getCurrent();
        console.log('Sketch.undo():', obj.type)
        history.undo()
        if(currState == null) {
            this.setState({actionsFromUser: false}, () => {
                obj.version -= 1
                canvas.add(obj)
                this.setState({actionsFromUser: true})
            })
        }
        else if (obj.version === 1) {
            obj.remove();
        }
        else {
            obj.setOptions(JSON.parse(prevState));
            obj.setCoords();
            obj.version -= 1;
            this._fc.renderAll()
        }
        this._fc.renderAll()
        if (this.props.onChange) {
            this.props.onChange()
        }
    };

    /**
     * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
     */
    redo = () => {
        let history = this._history;
        if(!history.canRedo()) {
            console.log('Sketch.redo(): nothing to redo')
            return
        }
        let canvas = this._fc;
        let [obj, prevState, currState] = history.redo();
        console.log('Sketch.redo():', obj.type, obj)
        if (obj.version === 0) {
            this.setState({actionsFromUser: false}, () => {
                canvas.add(obj);
                obj.version = 1
                this.setState({actionsFromUser: true})
            })
        }
        else if(!currState) {
            this.setState({actionsFromUser: false}, () => {
                canvas.remove(obj)
                obj.version += 1
                this.setState({actionsFromUser: true})
            })
        }
        else {
            obj.version += 1;
            obj.setOptions(JSON.parse(currState))
        }
        obj.setCoords();
        canvas.renderAll();
        if (this.props.onChange) {
            this.props.onChange()
        }
    };

    /**
     * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
     *
     * @returns {*} true if we can undo otherwise false
     */
    canUndo = () => {
        return this._history.canUndo()
    };

    /**
     * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
     *
     * @returns {*} true if we can redo otherwise false
     */
    canRedo = () => {
        return this._history.canRedo()
    };

    // /**
    //  * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
    //  *
    //  * Available Options are
    //  * <table style="width:100%">
    //  *
    //  * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
    //  * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
    //  * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
    //  * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
    //  * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
    //  * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
    //  * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
    //  * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
    //  *
    //  * </table>
    //  *
    //  * @returns {String} URL containing a representation of the object in the format specified by options.format
    //  */
    // toDataURL = (options) => this._fc.toDataURL(options);

    /**
     * Returns JSON representation of canvas
     *
     * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
     * @returns {string} JSON string
     */
    toJSON = (propertiesToInclude) => {
        var json = this._fc.toJSON(propertiesToInclude)
        json.cells = json.objects
        delete json.objects

        json.cells = json.cells.filter(cellJson => {
            var klass = fabric[cellJson.type]
            return klass.prototype.shouldPersist
        })

        console.log('toJSON', json)
        return json
    };

    /**
     * Populates canvas with data from the specified JSON.
     *
     * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
     *
     * @param json JSON string or object
     */
    fromJSON = (json, callback) => {
        if (!json) return

        let canvas = this._fc
        canvas.clear()

        setTimeout(() => {
            // console.log('loadFromJSON starting...')
            this.setState({actionsFromUser: false}, () => {
                var cells = json.cells.map(cellJson => {
                    var klass = fabric[cellJson.type]
                    if(!klass) {
                        console.log('Sketch::fromJson: unrecognized type:', cellJson.type)
                        return null
                    }
                    var cell = new klass(cellJson)
                    return cell
                }).filter(cell => !!cell) //filter out nulls from unrecognized types
                cells.forEach(cell => {
                    try {
                        if(cell.revive)
                            cell.revive(cells)
                    } catch (e) {
                        console.log('error reviving cell:', cell, e)
                    }
                })
                cells.forEach(cell => {
                    canvas.add(cell)
                })
                canvas.renderAll()
                this.setState({actionsFromUser: true}, callback)
                this._selectedTool.configureCanvas(this.props)
            })
        }, 500)
    }

    /**
     * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
     * used as needed in order to undo the clear if possible
     *
     * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
     * @returns {string} JSON string of the canvas just cleared
     */
    clear = (propertiesToInclude) => {
        if(!confirm('Are you sure you want to clear the sketch? (This cannot be undone)'))
            return

        // let discarded = this.toJSON(propertiesToInclude);
        this.setState({actionsFromUser: false}, () => {
            this._fc.clear()
            this._fc.add(new fabric.StartNode({
                left: 100,
                top: 100,
            }))
            this._history.clear()
            this.setState({actionsFromUser: true})
        })

        // return discarded
    };

    getActiveObject = () => {
        return this._fc.getActiveObject()
    }

    deleteActiveObject = () => {
        var activeObject = this._fc.getActiveObject()
        this._fc.remove(activeObject)
    }
    // /**
    //  * Sets the background from the dataUrl given
    //  *
    //  * @param dataUrl the dataUrl to be used as a background
    //  * @param options
    //  */
    // setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    //     let canvas = this._fc;
    //     if (options.stretched) {
    //         delete options.stretched;
    //         Object.assign(options, {
    //             width: canvas.width,
    //             height: canvas.height
    //         })
    //     }
    //     if (options.stretchedX) {
    //         delete options.stretchedX;
    //         Object.assign(options, {
    //             width: canvas.width
    //         })
    //     }
    //     if (options.stretchedY) {
    //         delete options.stretchedY;
    //         Object.assign(options, {
    //             height: canvas.height
    //         })
    //     }
    //     let img = new Image();
    //     img.onload = () => canvas.setBackgroundImage(new fabric.Image(img),
    //         () => canvas.renderAll(), options);
    //     img.src = dataUrl
    // };

    componentDidMount = () => {
        let {
            tool,
            value,
            defaultValue,
            undoSteps
        } = this.props;

        let canvas = this._fc = new fabric.Canvas(this._canvas, {
         preserveObjectStacking: true,
         // renderOnAddRemove: false,
         // skipTargetFind: true,

         selection: true, //allow group selection
         selectionFullyContained: false,
         // lockRotation: true,
         showControls: false,

        });
        window.canvas = canvas //TODO dev only
        window.sketch = this //TODO dev only

        // canvas.setDimensions({width:800, height:500});

        this._initTools(canvas);

        let selectedTool = this._tools[tool];
        selectedTool.configureCanvas(this.props);
        this._selectedTool = selectedTool;

        // Initialize History, with maximum number of undo steps
        this._history = new History(undoSteps);

        // Events binding
        canvas.on('object:added', this._onObjectAdded);
        canvas.on('object:modified', this._onObjectModified);
        canvas.on('object:removed', this._onObjectRemoved);
        canvas.on('mouse:down', this._onMouseDown);
        canvas.on('mouse:move', this._onMouseMove);
        canvas.on('mouse:up', this._onMouseUp);
        canvas.on('mouse:out', this._onMouseOut);
        canvas.on('selection:created', this._onSelectionCreated);
        // canvas.on('selection:updated', this._onSelectionUpdated);
        // canvas.on('before:selection:cleared', this._onBeforeSelectionCleared);
        canvas.on('selection:cleared', this._onSelectionCleared);


        window.addEventListener('resize', this._resize, false);
        canvas.wrapperEl.addEventListener('mousewheel', this._onMouseWheel)
        canvas.wrapperEl.addEventListener('DOMMouseScroll', this._onMouseWheel)
        window.addEventListener('keydown', this._onKeyDown)
        window.addEventListener('keyup', this._onKeyUp)

        // this.disableTouchScroll(); //why?
        this._resize();

        // initialize canvas with controlled value if exists
        (value || defaultValue) && this.fromJSON(value || defaultValue);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this._resize)
        canvas.wrapperEl.removeEventListener('mousewheel', this._onMouseWheel)
        canvas.wrapperEl.removeEventListener('DOMMouseScroll', this._onMouseWheel)
        window.removeEventListener('keydown', this._onKeyDown)
        window.removeEventListener('keyup', this._onKeyUp)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.parentWidth !== prevState.parentWidth
            || this.props.width !== prevProps.width
            || this.props.height !== prevProps.height) {

            this._resize()
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.tool !== nextProps.tool) {
            this._selectedTool = this._tools[nextProps.tool] || this._tools[Tool.Select]
        }

        //Bring the cursor back to default if it is changed by a tool
        this._fc.defaultCursor = 'default';
        this._selectedTool.configureCanvas(nextProps);

    //     if (this.props.backgroundColor !== nextProps.backgroundColor) {
    //         this._backgroundColor(nextProps.backgroundColor)
    //     }

    //     if ((this.props.value !== nextProps.value) || (nextProps.value && nextProps.forceValue)) {
    //         this.fromJSON(nextProps.value);
    //     }
    };

    render = () => {
        let {
            className,
            style,
            width,
            height
        } = this.props;

        let canvasDivStyle = Object.assign({}, style ? style : {},
            width ? {width: width} : {},
            height ? {height: height} : {height: 512});

        return (
            <div
                className='sketch'
                ref={(c) => this._container = c}
                // style={canvasDivStyle}
                >
                <canvas
                    id={Ops.uuid4()}
                    ref={(c) => this._canvas = c}>
                    Sorry, Canvas HTML5 element is not supported by your browser
                    :(
                </canvas>
            </div>
        )
    }
}

// Sketch.propTypes = {
//     // the color of the line
//     lineColor: PropTypes.string,
//     // The width of the line
//     lineWidth: PropTypes.number,
//     // the fill color of the shape when applicable
//     fillColor: PropTypes.string,
//     // the background color of the sketch
//     backgroundColor: PropTypes.string,
//     // the opacity of the object
//     opacity: PropTypes.number,
//     // number of undo/redo steps to maintain
//     undoSteps: PropTypes.number,
//     // The tool to use, can be pencil, rectangle, circle, brush;
//     tool: PropTypes.string,
//     // image format when calling toDataURL
//     imageFormat: PropTypes.string,
//     // Sketch data for controlling sketch from
//     // outside the component
//     value: PropTypes.object,
//     // Set to true if you wish to force load the given value, even if it is  the same
//     forceValue: PropTypes.bool,
//     // Specify some width correction which will be applied on auto resize
//     widthCorrection: PropTypes.number,
//     // Specify some height correction which will be applied on auto resize
//     heightCorrection: PropTypes.number
// };

Sketch.defaultProps = {
    lineColor: 'black',
    lineWidth: 10,
    fillColor: 'transparent',
    backgroundColor: 'transparent',
    opacity: 1.0,
    undoSteps: 25,
    // tool: DEFAULT_TOOL,
    widthCorrection: 2,
    heightCorrection: 0,
    forceValue: false
};

export default Sketch